import React from "react"

import {FieldDef} from "../modeler/FieldDef.js"

import {MC} from "./MC.js"
import {MCHistory} from "./MCHistory.js"
import {MCCache} from "./MCCache.js"
import {FlowInput} from "./FlowInput.jsx"
import {LogConsole} from "./LogConsole.jsx"
import {Dialog} from "./Dialog.jsx"
import {Form} from "./Form.jsx"
import {Flow} from "./Flow.js"
import {Message} from "./Message.jsx"
import {Value} from "./Value.js"

class ReactFlow extends React.Component {

  static flowTemplate = 'miniapp/def/{configuration}?name={flowName}&lang={lang}'
  static flowServerUrl = 'miniapp/api/'
  flow = null
  state = {dimmer: false, showLogConsole: false, inputData: null, debugBarPos: 'right', ...this.calculateStateFromProps(this.props)}
  children = {}
  destroyedChildren = {}
  dimmerRequested = false
  containerRef = React.createRef()
  unmounted = false
  isFormActive = false
  key = MC.generateId()

  componentDidMount() {
    if (this.props.parent && this.props.emdialogId) {
      this.props.parent.children[this.props.emdialogId] = this
    } else {
      window.addEventListener("beforeunload", this.onUnload, false)
    }
    if (!this.props.parent && this.props.mconf.flowName) { // routing only in root flow mode
      let base = document.querySelector('base').href.split('?')[0]
      base = base.substring(0, base.lastIndexOf("/") + 1)
      document.querySelector('base').href = base
      window.onpopstate = this.onBackOrForwardButtonEvent
    }
    this.startFlow()
  }

  componentDidUpdate(prevProps) {
    if (!MC.objectEqual(prevProps, this.props, true, ['flow', 'fields', 'parent'])) {
      this.setState(this.calculateStateFromProps(this.props))
    }
    if (this.props.start && MC.isFunction(this.props.resetStart)) {
      this.props.resetStart()
    }
    this.startFlow()
  }

  componentWillUnmount() {
    this.unmounted = true
    if (this.props.emdialogId && this.state.state === 'form' && this.state.formData.flow && this.state.formData.flow.context.action.kind == 'form' && !this.props.parent.unmounted) { // store state of form in em dialog before destructing resize
      this.props.parent.destroyedChildren[this.props.emdialogId] = {formData: this.state.formData, destroyedChildren: this.destroyedChildren, flow: this.flow}
    }
    if (this.props.parent && this.props.emdialogId) {
      delete this.props.parent.children[this.props.emdialogId]
    } else {
      this.destroyedChildren = null
      window.removeEventListener("beforeunload", this.onUnload, false)
    }
    if (this.flow) {
      this.flow.reactFlowObj = null
      this.flow = null
    }
    this.destroryActivityMonitor()
  }

  componentDidCatch(error) {
    if (this.flow) {
      this.flow.endOperationException('SYS_UnrecoverableRuntimeExc', error)
    } else {
      MC.error(error)
    }
  }

  getLoaderDelay = (conf) => {
    let laoderDelay = conf && Value.getProperty(conf, 'mini:loaderDelay').value
    let delay = conf &&  MC.isNumeric(laoderDelay) ? laoderDelay : this.flow && MC.isNumeric(this.flow.getCfgParameter('mini:loaderDelay')) ? this.flow.getCfgParameter('mini:loaderDelay') : "800"
    return parseInt(delay)
  }

  requestDimmer(stateToSet, conf) {
    let self = this
    this.dimmerRequested = true
    if (!MC.isNull(stateToSet)) {
      this.setState(stateToSet)
    }
    setTimeout(() => {
      if (self.dimmerRequested) {
        self.dimmerRequested = false
        if (!this.unmounted) {
          if (self.state.loader != 'none' && (self.state.loader != 'init' || !self.state.firstFormRendered)) {
            self.setState({dimmer: true})
          }
        }
      }
    }, this.getLoaderDelay(conf))
  }

  stopDimmer(stateToSet) {
    this.dimmerRequested = false
    this.setState({dimmer: false, ...stateToSet})
  }  

  calculateStateFromProps(props) {
    if (props.savedState) {
      let state = props.savedState
      if (this.flow == state.flow) {
        return
      }
      this.flow = state.flow
      this.flow.reactFlowObj = this
      if (state.destroyedChildren) {
        this.destroyedChildren = state.destroyedChildren
      }
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all'
      return {showInput: false, flowName: state.flow.flowName, configuration: state.flow.confPath, env: props.env, logLevel: props.debug,
        input: props.input, start: props.start, loader: loader, state: 'form', formData: state.formData, dimmer: false, fromSavedState: true}
    } else {
      const configuration = props.configuration
      const flowName = props.flowName
      const showInput = !(configuration && flowName || MC.isPlainObject(props.env?.configuration))
      const loader = ['init', 'none'].indexOf(props.loader) > -1 ? props.loader : 'all'
      return {showInput: showInput, flowName: flowName, configuration: configuration, env: props.env, logLevel: props.debug,
        input: props.input, start: props.start, loader: loader, fromSavedState: false}
    }
  }

  startFlow(routing = false) {
    if (this.state.start || routing) {
      this.destroyedChildren = {} // must destroy all picked children for to not flashing
      if (this.state.start) {
        this.setState({start: false})
      }
      if (this.flow) {
        this.flow.isRenderingInterupted = true
        this.flow.clearLogicTimers()
      }
      const flow = new Flow()
      flow.init(this).setBaseUrl(this.props.baseUrl ? this.props.baseUrl : this.props.mconf.baseUrl).setFlowConfiguration(this.state.configuration, this.state.flowName).setLang(this.props.mconf.lang)
      if (routing) {
        flow.setEnv(this.flow.context.data.env)
      }
      if (this.state.env) {
        flow.setEnv(this.state.env)
      }
      if (this.props.afterRenderFormFunction) {
        flow.setAfterRenderFormFunction(this.props.afterRenderFormFunction);
      }
      flow.setWantedLogLevel(this.state.logLevel)
      if (this.props.instanceId) {
        flow.setInstanceId(this.props.instanceId)
      }
      this.flow = flow
      flow.loadAndStart(this.props.embedded ? this.state.input : Value.fromJson(this.state.input), null).then((opts) => {
        if (opts.leaveFlow) {
          let {calls, input} = {...opts}
          this.setState({start: true, flowName: calls, input: this.props.embedded ? input : Value.toJson(input)})
        } else {
          if (MC.isFunction(this.props.onEndFunction)) {
            if (this.props.clearStateOnEnd) {
              this.stopDimmer({state: null})
            }
            this.props.onEndFunction(this.props.embedded ? opts.output : Value.toJson(opts.output), undefined, opts.opts)
          } else {
            this.stopDimmer({state: this.flow.debug() ? 'output' :  null, output: Value.toJson(opts.output)})
          }
          flow.doSubmitByParentResolve(true)
        }
      }).catch((args) => {
        let [type, message] = [...args]
        if (MC.isFunction(this.props.onEndFunction)) {
          if (this.props.clearStateOnEnd) {
            this.stopDimmer({state: null})
          } else {
            this.stopDimmer({state: 'exception', exception: {type: type, message: message}})
          }
          this.props.onEndFunction(type, message, {endAction: null})
        } else {
          this.stopDimmer({state: 'exception', exception: {type: type, message: message}})
        }
        flow.doSubmitByParentResolve(false)
      })
    }
  }

  showLogConsole = (beta) => {
    this.setState({showLogConsole: true, start: false, betaConsole: beta})
  }

  hideLogConsole = () => {
    this.setState({showLogConsole: false, start: false})
    document.querySelector('body').classList.remove('showing-modal')
  }

  toggleLogConsole = () => {
   if (this.state.showLogConsole) {
     this.hideLogConsole()
   } else {
     this.showLogConsole(false)
   }
  }
  
  toggleBetaLogConsole = () => {
    if (this.state.showLogConsole) {
      this.hideLogConsole()
    } else {
      this.showLogConsole(true)
    }
   } 

  showInputPanel = () => {
    this.setState({showInput: true, showLogConsole: false, start: false})
  }

  clearLog = () => {
    MCHistory.clear()
  }

  clickRunFlow = (flowName, inputData) => {
    let input = {}
    if (inputData) {
      let inputDataObj = MC.xmlStringToObject(inputData, null, false)
      if (inputDataObj.data) {
        input = inputDataObj.data
      }
    }
    var showInput = true;
    var start = false;
    if (flowName) {
      MCHistory.clear();
      MCCache.clear();
      showInput = false;
      start = true;
    }
    this.setState({flowName: flowName, inputData: inputData, input: input, showInput: showInput, start: start})
  }

  routeTo = (e, url) => {
    if (this.state.dialog) { // destroy opened dialog
      document.querySelector('body').classList.remove('showing-modal')
      this.flow.endDialog(null, null, true)
    }
    if (this.props.parent) {  
      this.props.parent.routeTo(e, url)
      return
    }
    if (e) {
      e.preventDefault()
    }
    let isRouting = false
    if (this.props.mconf.flowName) {
      if (url.indexOf('://') < 0) {
        if (url == "/") { // app space root
          url = ""
        }
        const base = document.querySelector('base').href.split('?')[0]
        const utils = new MC.URLUtils(url, base)
        const target = utils.protocol + '//' + utils.host + utils.href
        if (target.startsWith(base)) { // routing only links in appplication space, other links are normally navigated by browser
          isRouting = true
          this.isChangedAsPromise(url).then((changed) => {
            if (changed && !confirm(MC.formatMessage('beforeleave', this.props.mconf.lang))) {
              return
            } else {
              const currRi = window.location.href.substring(base.length)
              url = MC.ensureSystemParameters(currRi, url)
              if (url !== currRi) {
                if (!window.history.state) {
                  window.history.pushState({mncrouting: true}, '', base + currRi)
                }
                window.history.pushState({mncrouting: true}, '', base + url)
              } 
              this.startFlow(true)
              return
            }
          })
        }
      }
    }
    if (!isRouting) {
      window.location.href = url
    }
  }

  isChanged = (url) => {
    if (this.isFormActive && this.state.formData && this.state.formData.param) {
      MC.putFieldParamValue(this.state.formData.param, "@unloadLocation", url)
      this.state.formData.flow.eventForm(null, 'beforeunload')
      if (this.state.formData.param['@changed']) {
        return true
      }
    }
    if (!MC.isEmptyObject(this.children)) {
      for (let childKey in this.children) {
        if (this.children[childKey].isChanged(url)) {
          return true
        }
      }
    }
    return false
  }

  isChangedAsPromise = (url) => {
    let self = this
    return new Promise(function (resolve) {
      let promises = []
      if (self.isFormActive && self.state.formData && self.state.formData.param) {
        MC.putFieldParamValue(self.state.formData.param, "@unloadLocation", url)
        let unloadPromiseObject = {}
        let promise = new Promise(function (resolve, reject) {
          unloadPromiseObject = {resolve: resolve, reject: reject}
        })
        self.unloadPromiseObject = unloadPromiseObject
        promises.push(promise)
        self.state.formData.flow.eventForm(null, 'beforeunload')
      }
      if (!MC.isEmptyObject(self.children)) {
        for (let childKey in self.children) {
          promises.push(self.children[childKey].isChangedAsPromise(url))
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function (results) {
          for (var i=0; i<results.length; i++) {
            if (results[i]) {
              resolve(true)
              return
            }
          }
          resolve(false)
        })
      } else {
        resolve(false)
      }
    })
  }

  resolveUnload(formData) {
    if (this.unloadPromiseObject) {
      this.unloadPromiseObject.resolve(formData.param['@changed'] ? true : false)
      this.unloadPromiseObject = null
    }
  }

  onUnload = (e) => {
    if (this.isChanged(null)) {
      let mess = MC.formatMessage('beforeleave', this.props.mconf.lang)
      e.preventDefault()
      e.returnValue = mess
      return mess
    }
  }

  onBackOrForwardButtonEvent = (e) => {
    if (!e.state || !e.state.mncrouting) {
      return
    }
    if (this.props.mconf.flowName) {
      this.startFlow(true)
    }
  }

  pickDestroyedChild = (id) => {
    if (!MC.isEmptyObject(this.destroyedChildren) && this.destroyedChildren[id]) {
      let res = this.destroyedChildren[id]
      delete this.destroyedChildren[id]
      return res
    }
    return false
  }

  moveDebugBar = () => {
    this.setState({debugBarPos: this.state.debugBarPos == 'left' ? 'right' : 'left'})
  }

  initActivityMonitor = (minutes) => {
    if (!this.props.parent && !this.inacvitityMinutes) {
      this.lastActivity = Date.now()
      document.addEventListener("keydown", this.handleActivity)
      document.addEventListener("mousedown", this.handleActivity)
      this.inacvitityMinutes = parseInt(minutes)
      this.inacvitityInterval = setInterval(this.checkActivity, 60000)
    }  
  }
  
  destroryActivityMonitor = () => {
    if (this.inacvitityMinutes) {
      delete this.inacvitityMinutes
      this.inacvitityInterval = clearInterval(this.inacvitityInterval)
      document.removeEventListener("keydown", this.handleActivity)
      document.removeEventListener("mousedown", this.handleActivity)
    }
  }  

  handleActivity = () => { 
    if (this.props.parent) {
      this.props.parent.handleActivity()
      return
    }
    this.lastActivity = Date.now()
    if (!this.inacvitityInterval) { // re-activate interval after activity
      this.inacvitityInterval = setInterval(this.checkActivity, 60000)
    }
  }

  checkActivity = () => {
    if (Math.floor( (Date.now()-this.lastActivity) / 60000) >= this.inacvitityMinutes) {
      this.inacvitityInterval = clearInterval(this.inacvitityInterval)
      let urlarr = window.location.href.split("/")
      window.postMessage({name: 'sessionInactivityEvent'}, urlarr[0] + "//" + urlarr[2]);
    }
  }

  handleSubmitByParent = (action) => {
    let self = this
    return new Promise(function (resolve, reject) {     
      if (self.isFormActive) {
        self.handleSubmitByParentChilds(action).then(() => {
          self.submitByParentResolve = resolve
          self.submitByParentReject = reject
          self.state.formData.flow.handleSubmit(self.state.formData, action)
        }).catch(() => { 
          reject() 
        })
      } else {
        reject()
      }
    })
  }

  handleSubmitByParentChilds = (action) => {
    let self = this
    return new Promise(function (resolve, reject) {
      let promises = []
      for (const reactF of Object.values(self.children)) {
        if (MC.isSubmittingByParent(reactF)) {
          promises.push(reactF.handleSubmitByParent(action))
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function () {
          resolve()
        }).catch(() => {
          reject()
        })
      } else {
        resolve()
      }
    })
  }

  validateByParent = (action) => {
    let self = this
    return new Promise(function (resolve, reject) {
      let parentValid = true
      if (self.isFormActive) {
        if (action == 'submit' && !MC.validateFieldTree(self.state.formData, self.state.formData, 0)) {
          self.forceUpdate()
          parentValid = false
        }
        self.validateByParentChilds(resolve, reject, parentValid, action)
      } else {
        setTimeout(() => { 
          if (!self.isFormActive) {
            reject()
          } else {
            if (action == 'submit' && !MC.validateFieldTree(self.state.formData, self.state.formData, 0)) {
              self.forceUpdate()
              parentValid = false
            }
            self.validateByParentChilds(resolve, reject, parentValid, action)
          }
        }, 500)
      }
    })  
  }

  validateByParentChilds = (resolve, reject, parentValid, action) => {
    let promises = []
    for (const reactF of Object.values(this.children)) {
      if (reactF.props.submitByParent || MC.getFieldParamBooleanValue(reactF.state?.formData?.param, '@submitByParent')) {
        reactF.flow.focusedOnFirst = true
        promises.push(reactF.validateByParent(action))
      }
    }
    if (promises.length > 0) {
      Promise.all(promises).then(function () {
        if (parentValid) {
          resolve()
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })  
    } else {
      if (parentValid) {
        resolve()
      } else {
        reject()
      }
    }
  }  

  render() {
    var state = this.state.state
    var content;
    if (state == 'exception') {
      if (this.flow.debug() || this.props.debug) {
        let exception = this.state.exception
        if (exception.message) {
          content = <React.Fragment>Exception <strong>{exception.type}</strong> thrown: <pre>{exception.message}</pre></React.Fragment>
        } else {
          content = <React.Fragment>Exception <strong>{exception.type}</strong> thrown.</React.Fragment>
        }
      } else {
        content = 'Error!'
      }
      content = <div className="mnc red message">{content}</div>
    } else if (state == 'output') {
      content = <div className="mnc message"><strong>Output:</strong><br/> <pre>{JSON.stringify(this.state.output, null, 2)}</pre></div>;
    } else if (state == 'form') {
      FieldDef.setProto(this.state.formData)
      let stopRender = !this.isFormActive || this.state.dialog != null
      content = <Form form={this.state.formData} stopRender={stopRender} element={this.containerRef.current} key="form" embedded={this.props.embedded} mconf={this.props.mconf}/>;
      if (this.props.savedState) {
        this.key = MC.generateId() // generate new key after restore from saved state, otherwise rerender by form logic inside em. dialog not working
      }
    }
    if (!content && this.state.dimmer) {
      content = <div><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p></div>
    }
    var inputPanel = null;
    var logConsole = null;
    if (this.props.debug && this.props.console) {
      if (this.state.showInput) {
        inputPanel = <div><FlowInput flowName={this.state.flowName} inputData={this.state.inputData} configuration={this.state.configuration} onRun={this.clickRunFlow} mconf={this.props.mconf}/></div>
      } else {
        inputPanel = [];
        inputPanel.push(<button key="k1" className="mnc icon button" onClick={this.showInputPanel}><i className="setting icon" title="Configuration panel"></i></button>);
        inputPanel.push(<button key="k2" className="mnc icon button" onClick={this.toggleLogConsole}><i className="bug icon" title="Toggle new log console"></i></button>);
        inputPanel.push(<button key="k2a" className="mnc yellow basic icon button" onClick={this.toggleBetaLogConsole}><i className="yellow bug icon" title="Toggle new log console - experimental"></i></button>);
        inputPanel.push(<button key="k3" className="mnc icon button" onClick={this.clearLog}><i className="trash alternate icon" title="Clear log"></i></button>);
        if (this.state.formData && this.state.formData.flow && this.state.formData.flow.flow) {
          inputPanel.push(<a key="k4" className="mnc icon button" href={this.props.mconf.baseUrl + 'miniapp/formedit/' + this.state.formData.flow.flow.model + '?id=' + this.state.formData.formId} target="_blank"><i className="paint brush icon" title="Open form in repository"></i></a>)
        }
        let rootStyle = {position: 'fixed', zIndex: 100000, backgroundColor: '#CEEAFF', paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px', border: '2px solid #2185d0', top: '3px'}
        rootStyle = Object.assign(rootStyle, this.state.debugBarPos == 'left' ? {left: '3px', paddingRight: '40px'} : {right: '3px', paddingLeft: '40px'})
        let positionerStyle = {backgroundColor: '#2185d0', color: '#fff', position: 'absolute', top: 0, cursor: 'pointer'}
        positionerStyle = Object.assign(positionerStyle, this.state.debugBarPos == 'left' ? {right: '0px'} : {left: '0px'})
        inputPanel = (
          <div style={rootStyle}>
            <a style={positionerStyle} onClick={this.moveDebugBar}><i className={MC.classes('angle double', this.state.debugBarPos == 'left' ? 'right' : 'left', 'icon')}></i></a>
            {inputPanel}
          </div>)
      }
      logConsole = <LogConsole open={this.state.showLogConsole} onClose={this.hideLogConsole} mconf={this.props.mconf} beta={this.state.betaConsole} flowInstanceId={this.flow ? this.flow.instanceId : null}/>
    }
    return (
      <div ref={this.containerRef} key={this.key}>
        {inputPanel}
        <div className={MC.classes("dimmable", this.state.dimmer && "dimmed")}>
          <div className="mnc dimmer">
            <div className="content">
              <div className="mnc loader"></div>
            </div>
          </div>
          {content}
          <Dialog key="modaldialog" dialog={this.state.dialog}/>
          <Message key="message" data={this.state.message}/>
        </div>
        {logConsole}
      </div> )
  }

}

if (!window.React) {
  window.React = React
}

export {ReactFlow}