import React from 'react'
import Animate from '../react-move/Animate.jsx'

import {MC} from '../MC.js'
import {PagingDots, PreviousButton, NextButton, ScrollTransition} from './components.jsx'
import {addAccessibility, getSlideHeight, swipeDirection, shouldUpdate, calcSomeInitialState, 
        getDecoratorStyles, getSliderStyles, getFrameStyles, getTransitionProps, easeExpOut} from './utilities.js'        

class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.displayName = 'Carousel';
    this.clickDisabled = false;
    this.isTransitioning = false;
    this.touchObject = {};
    this.childNodesMutationObs = null;

    const items = this.buildItems(this.props)
    this.state = {
      currentSlide: this.props.slideIndex,
      dragging: false,
      easing: easeExpOut,
      hasInteraction: false, // to remove animation from the initial slide on the page load when non-default slideIndex is used
      isWrappingAround: false,
      left: 0,
      resetWrapAroundPosition: false,
      items: items,
      slideCount: items.length,
      top: 0,
      wrapToIndex: null,
      ...calcSomeInitialState(this.props)
    }
    this.autoplayIterator = this.autoplayIterator.bind(this);
    this.calcSlideHeightAndWidth = this.calcSlideHeightAndWidth.bind(this);
    this.getChildNodes = this.getChildNodes.bind(this);
    this.getMouseEvents = this.getMouseEvents.bind(this);
    this.getOffsetDeltas = this.getOffsetDeltas.bind(this);
    this.getTargetLeft = this.getTargetLeft.bind(this);
    this.getTouchEvents = this.getTouchEvents.bind(this);
    this.goToSlide = this.goToSlide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.onReadyStateChange = this.onReadyStateChange.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.resetAutoplay = this.resetAutoplay.bind(this);
    this.setDimensions = this.setDimensions.bind(this);
    this.setLeft = this.setLeft.bind(this);
    this.startAutoplay = this.startAutoplay.bind(this);
    this.stopAutoplay = this.stopAutoplay.bind(this);
    this.establishChildNodesMutationObserver = this.establishChildNodesMutationObserver.bind(this);
  }

  buildItems(props) {
    let field = props.data
    let values = MC.getFieldParamValue(field.param, 'value')
    if (!Array.isArray(values)) {
      return []
    }
    let target = MC.getFieldParamValue(field.param, '@target')
    if (['blank', 'parent', 'top'].indexOf(target) > -1) {
      target = '_' + target
    } else {
      target = null
    }
    const imgStyle = {width: '100%'}
    let items = []
    for (let i=0; i<values.length; i++) {
      const value = values[i]
      if (!value) {
        continue
      }
      const clickUrl = MC.rebaseLink(field.flow, value['@clickUrl'])
      if (value['@url']) {
        let url = MC.rebaseUrl(field.flow, value['@url'])
        if (MC.isNull(clickUrl)) {
          items.push(<img src={url} key={i} onLoad={this.setSlideHeightAndWidth} style={imgStyle}/>)
        } else {
          items.push(<a href={clickUrl} target={target}><img src={url} key={i} onLoad={this.setSlideHeightAndWidth} style={imgStyle}/></a>)
        }
      } else if (value['@base64']) {
        const contentType = value['@contentType'] ? value['@contentType'] : 'image/jpg'
        let data = 'data:' + contentType + ';base64,' + value['@base64']
        if (MC.isNull(clickUrl)) {
          items.push(<img src={data} key={i} style={imgStyle}/>)
        } else {
          items.push(<a href={clickUrl} target={target}><img src={data} key={i} style={imgStyle}/></a>)
        }
      }
    }
    return items
  }

  componentDidMount() {
    this.mounted = true;
    this.setLeft();
    this.setDimensions();
    this.bindEvents();
    this.establishChildNodesMutationObserver();
    if (this.props.autoplay) {
      this.startAutoplay();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!MC.objectEqual(prevProps, this.props, true, ['flow', 'fields', 'reactWidget', 'parent'])) {
      const nextProps = this.props 
      const items = this.buildItems(nextProps)
      const slideCount = items.length
      const slideCountChanged = slideCount !== this.state.slideCount
      this.setState(prevState => ({slideCount, items, currentSlide: slideCountChanged ? nextProps.slideIndex : prevState.currentSlide}))
      if (slideCount <= this.state.currentSlide) {
        this.goToSlide(Math.max(slideCount - 1, 0), nextProps)
      }
      const updateDimensions = slideCountChanged || shouldUpdate(prevProps, nextProps, ['cellSpacing', 'vertical', 'slideWidth', 'slideHeight', 'heightMode', 'slidesToScroll', 'slidesToShow', 'cellAlign'])
      if (updateDimensions) {
        this.setDimensions(nextProps)
      }
      if (prevProps.slideIndex !== nextProps.slideIndex && nextProps.slideIndex !== this.state.currentSlide && !this.state.isWrappingAround) {
        this.goToSlide(nextProps.slideIndex, prevProps)
      }
      if (prevProps.autoplay !== nextProps.autoplay) {
        if (nextProps.autoplay) {
          this.startAutoplay()
        } else {
          this.stopAutoplay()
        }
      }
    } else {
      const slideChanged = prevState.currentSlide !== this.state.currentSlide;
      const heightModeChanged = prevProps.heightMode !== this.props.heightMode;
      const axisChanged = prevProps.vertical !== this.props.vertical;
      if (axisChanged) {
        this.onResize();
      } else if (slideChanged || heightModeChanged) {
        this.setSlideHeightAndWidth();
      }
    }
  }

  componentWillUnmount() {
    this.unbindEvents();
    this.disconnectChildNodesMutationObserver();
    this.stopAutoplay();
    this.mounted = false;
  }

  establishChildNodesMutationObserver() {
    const childNodes = this.getChildNodes();
    if (childNodes.length && 'MutationObserver' in window) {
      this.childNodesMutationObs = new MutationObserver(mutations => {
        mutations.forEach(() => {
          this.setSlideHeightAndWidth();
        });
      });

      const observeChildNodeMutation = node => {
        this.childNodesMutationObs.observe(node, {
          attributes: true,
          attributeFilter: ['style'],
          attributeOldValue: false,
          characterData: false,
          characterDataOldValue: false,
          childList: false,
          subtree: false
        });
      };

      const childNodesArray = Array.from(childNodes);

      for (const node of childNodesArray) {
        observeChildNodeMutation(node);
      }
    }
  }

  disconnectChildNodesMutationObserver() {
    if (this.childNodesMutationObs instanceof MutationObserver) {
      this.childNodesMutationObs.disconnect();
    }
  }

  getTouchEvents() {
    if (this.props.swiping === false) {
      return {
        onTouchStart: this.handleMouseOver,
        onTouchEnd: this.handleMouseOut
      };
    }

    return {
      onTouchStart: e => {
        this.touchObject = {
          startX: e.touches[0].pageX,
          startY: e.touches[0].pageY
        };
        this.handleMouseOver();

        if (this.props.onDragStart) {
          this.props.onDragStart();
        }

        this.setState({
          dragging: true
        });
      },
      onTouchMove: e => {
        const direction = swipeDirection(
          this.touchObject.startX,
          e.touches[0].pageX,
          this.touchObject.startY,
          e.touches[0].pageY,
          this.props.vertical
        );

        if (direction !== 0) {
          e.preventDefault();
        }

        const length = this.props.vertical
          ? Math.round(
              Math.sqrt(
                Math.pow(e.touches[0].pageY - this.touchObject.startY, 2)
              )
            )
          : Math.round(
              Math.sqrt(
                Math.pow(e.touches[0].pageX - this.touchObject.startX, 2)
              )
            );

        this.touchObject = {
          startX: this.touchObject.startX,
          startY: this.touchObject.startY,
          endX: e.touches[0].pageX,
          endY: e.touches[0].pageY,
          length,
          direction
        };

        this.setState({
          left: this.props.vertical
            ? 0
            : this.getTargetLeft(
                this.touchObject.length * this.touchObject.direction
              ),
          top: this.props.vertical
            ? this.getTargetLeft(
                this.touchObject.length * this.touchObject.direction
              )
            : 0
        });
      },
      onTouchEnd: e => {
        this.handleSwipe();
        this.handleMouseOut();
      },
      onTouchCancel: e => {
        this.handleSwipe();
      }
    };
  }

  getMouseEvents() {
    if (this.props.dragging === false) {
      return {onMouseOver: this.handleMouseOver, onMouseOut: this.handleMouseOut}
    }

    return {
      onMouseOver: this.handleMouseOver,

      onMouseOut: this.handleMouseOut,

      onMouseDown: e => {
        this.touchObject = {
          startX: e.clientX,
          startY: e.clientY
        };

        if (this.props.onDragStart) {
          this.props.onDragStart();
        }

        this.setState({
          dragging: true
        });
      },

      onMouseMove: e => {
        if (!this.state.dragging) {
          return;
        }

        const direction = swipeDirection(
          this.touchObject.startX,
          e.clientX,
          this.touchObject.startY,
          e.clientY,
          this.props.vertical
        );

        if (direction !== 0) {
          e.preventDefault();
        }

        const length = this.props.vertical
          ? Math.round(
              Math.sqrt(Math.pow(e.clientY - this.touchObject.startY, 2))
            )
          : Math.round(
              Math.sqrt(Math.pow(e.clientX - this.touchObject.startX, 2))
            );

        // prevents disabling click just because mouse moves a fraction of a pixel
        if (length >= 10) this.clickDisabled = true;

        this.touchObject = {
          startX: this.touchObject.startX,
          startY: this.touchObject.startY,
          endX: e.clientX,
          endY: e.clientY,
          length,
          direction
        };

        this.setState({
          left: this.props.vertical
            ? 0
            : this.getTargetLeft(
                this.touchObject.length * this.touchObject.direction
              ),
          top: this.props.vertical
            ? this.getTargetLeft(
                this.touchObject.length * this.touchObject.direction
              )
            : 0
        });
      },

      onMouseUp: e => {
        if (
          this.touchObject.length === 0 ||
          this.touchObject.length === undefined
        ) {
          this.setState({ dragging: false });
          return;
        }

        this.handleSwipe();
      },

      onMouseLeave: e => {
        if (!this.state.dragging) {
          return;
        }

        this.handleSwipe();
      }
    };
  }

  pauseAutoplay() {
    if (this.props.autoplay) {
      this.autoplayPaused = true;
      this.stopAutoplay();
    }
  }

  unpauseAutoplay() {
    if (this.props.autoplay && this.autoplayPaused) {
      this.startAutoplay();
      this.autoplayPaused = null;
    }
  }

  handleMouseOver() {
    if (this.props.pauseOnHover) {
      this.pauseAutoplay();
    }
  }

  handleMouseOut() {
    if (this.autoplayPaused) {
      this.unpauseAutoplay();
    }
  }

  handleClick(event) {
    if (this.clickDisabled === true) {
      if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      if (event.nativeEvent) {
        event.nativeEvent.stopPropagation();
      }
    }
  }

  handleSwipe() {
    let slidesToShow = this.state.slidesToShow;
    if (this.props.slidesToScroll === 'auto') {
      slidesToShow = this.state.slidesToScroll;
    }

    if (this.touchObject.length > this.state.slideWidth / slidesToShow / 5) {
      if (this.touchObject.direction === 1) {
        if (
          this.state.currentSlide >= this.state.slideCount - slidesToShow &&
          !this.props.wrapAround
        ) {
          this.setState({ easing: easeExpOut });
        } else {
          this.nextSlide();
        }
      } else if (this.touchObject.direction === -1) {
        if (this.state.currentSlide <= 0 && !this.props.wrapAround) {
          this.setState({ easing: easeExpOut });
        } else {
          this.previousSlide();
        }
      }
    } else {
      this.goToSlide(this.state.currentSlide);
    }

    // wait for `handleClick` event before resetting clickDisabled
    setTimeout(() => {
      this.clickDisabled = false;
    }, 0);
    this.touchObject = {};

    this.setState({
      dragging: false
    });
  }

  autoplayIterator() {
    if (this.props.wrapAround) {
      if (this.props.autoplayReverse) {
        this.previousSlide();
      } else {
        this.nextSlide();
      }
      return;
    }
    if (this.props.autoplayReverse) {
      if (this.state.currentSlide !== 0) {
        this.previousSlide();
      } else {
        this.stopAutoplay();
      }
    } else if (
      this.state.currentSlide !==
      this.state.slideCount - this.state.slidesToShow
    ) {
      this.nextSlide();
    } else {
      this.stopAutoplay();
    }
  }

  startAutoplay() {
    this.autoplayID = setInterval(
      this.autoplayIterator,
      this.props.autoplayInterval
    );
  }

  resetAutoplay() {
    if (this.props.autoplay && !this.autoplayPaused) {
      this.stopAutoplay();
      this.startAutoplay();
    }
  }

  stopAutoplay() {
    if (this.autoplayID) {
      clearInterval(this.autoplayID);
    }
  }

  // Animation Method

  getTargetLeft(touchOffset, slide) {
    let offset;
    const target = slide || this.state.currentSlide;
    switch (this.state.cellAlign) {
      case 'left': {
        offset = 0;
        offset -= this.props.cellSpacing * target;
        break;
      }
      case 'center': {
        offset = (this.state.frameWidth - this.state.slideWidth) / 2;
        offset -= this.props.cellSpacing * target;
        break;
      }
      case 'right': {
        offset = this.state.frameWidth - this.state.slideWidth;
        offset -= this.props.cellSpacing * target;
        break;
      }
    }

    let left = this.state.slideWidth * target;

    const lastSlide =
      this.state.currentSlide > 0 &&
      target + this.state.slidesToScroll >= this.state.slideCount;

    if (
      lastSlide &&
      this.props.slideWidth !== 1 &&
      !this.props.wrapAround &&
      this.props.slidesToScroll === 'auto'
    ) {
      left =
        this.state.slideWidth * this.state.slideCount - this.state.frameWidth;
      offset = 0;
      offset -= this.props.cellSpacing * (this.state.slideCount - 1);
    }

    offset -= touchOffset || 0;

    return (left - offset) * -1;
  }

  getOffsetDeltas() {
    let offset = 0;

    if (this.state.isWrappingAround) {
      offset = this.getTargetLeft(null, this.state.wrapToIndex);
    } else {
      offset = this.getTargetLeft(
        this.touchObject.length * this.touchObject.direction
      );
    }

    return {
      tx: [this.props.vertical ? 0 : offset],
      ty: [this.props.vertical ? offset : 0]
    };
  }

  isEdgeSwiping() {
    const { slideCount, slideWidth } = this.state;
    const { tx } = this.getOffsetDeltas();

    // returns true if tx offset is outside first or last slide
    return tx > 0 || -tx > slideWidth * (slideCount - 1);
  }

  // Action Methods

  goToSlide(index, props) {
    if (props === undefined) {
      props = this.props;
    }

    if (this.isTransitioning) {
      return;
    }

    this.setState({ hasInteraction: true, easing: easeExpOut });
    this.isTransitioning = true;

    if (index >= this.state.slideCount || index < 0) {
      if (!props.wrapAround) {
        this.isTransitioning = false;
        return;
      }
      if (index >= this.state.slideCount) {
        this.setState(
          prevState => ({
            left: props.vertical
              ? 0
              : this.getTargetLeft(
                  this.state.slideWidth,
                  prevState.currentSlide
                ),
            top: props.vertical
              ? this.getTargetLeft(
                  this.state.slideWidth,
                  prevState.currentSlide
                )
              : 0,
            currentSlide: 0,
            isWrappingAround: true,
            wrapToIndex: index
          }),
          () => {
            setTimeout(() => {
              this.resetAutoplay();
              this.isTransitioning = false;
            }, props.speed);
          }
        );
        return;
      } else {
        const endSlide = this.state.slideCount - this.state.slidesToScroll;
        this.setState(
          prevState => ({
            left: props.vertical
              ? 0
              : this.getTargetLeft(0, prevState.currentSlide),
            top: props.vertical
              ? this.getTargetLeft(0, prevState.currentSlide)
              : 0,
            currentSlide: endSlide,
            isWrappingAround: true,
            wrapToIndex: index
          }),
          () => {
            setTimeout(() => {
              this.resetAutoplay();
              this.isTransitioning = false;
            }, props.speed);
          }
        );
        return;
      }
    }

    this.setState(
      {
        currentSlide: index
      },
      () =>
        setTimeout(() => {
          this.resetAutoplay();
          this.isTransitioning = false;
        }, props.speed)
    );
  }

  nextSlide() {
    const childrenCount = this.state.slideCount;
    let slidesToShow = this.state.slidesToShow;

    if (this.props.slidesToScroll === 'auto') {
      slidesToShow = this.state.slidesToScroll;
    }

    if (
      this.state.currentSlide >= childrenCount - slidesToShow &&
      !this.props.wrapAround &&
      this.props.cellAlign === 'left'
    ) {
      return;
    }

    if (this.props.wrapAround) {
      this.goToSlide(this.state.currentSlide + this.state.slidesToScroll);
    } else {
      if (this.props.slideWidth !== 1) {
        this.goToSlide(this.state.currentSlide + this.state.slidesToScroll);
        return;
      }
      const offset = this.state.currentSlide + this.state.slidesToScroll;
      const nextSlideIndex =
        this.props.cellAlign !== 'left'
          ? offset
          : Math.min(offset, childrenCount - slidesToShow);
      this.goToSlide(nextSlideIndex);
    }
  }

  previousSlide() {
    if (this.state.currentSlide <= 0 && !this.props.wrapAround) {
      return;
    }

    if (this.props.wrapAround) {
      this.goToSlide(this.state.currentSlide - this.state.slidesToScroll);
    } else {
      this.goToSlide(
        Math.max(0, this.state.currentSlide - this.state.slidesToScroll)
      );
    }
  }

  bindEvents() {
    window.addEventListener('resize', this.onResize)
    document.addEventListener('readystatechange', this.onReadyStateChange)
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  }

  onResize() {
    this.setDimensions(null, this.props.onResize);
  }

  onReadyStateChange() {
    this.setDimensions();
  }

  onVisibilityChange() {
    if (document.hidden) {
      this.pauseAutoplay();
    } else {
      this.unpauseAutoplay();
    }
  }

  unbindEvents() {
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('readystatechange', this.onReadyStateChange)
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }

  calcSlideHeightAndWidth(props) {
    // slide height
    props = props || this.props;
    const childNodes = this.getChildNodes();
    const slideHeight = getSlideHeight(props, this.state, childNodes);

    //slide width
    const slidesToShow = props.slidesToShow
    const frame = this.frame;
    let slideWidth;

    if (this.props.animation === 'zoom') {
      slideWidth = frame.offsetWidth - (frame.offsetWidth * 15) / 100;
    } else if (typeof props.slideWidth !== 'number') {
      slideWidth = parseInt(props.slideWidth);
    } else if (props.vertical) {
      slideWidth = (slideHeight / slidesToShow) * props.slideWidth;
    } else {
      slideWidth = (frame.offsetWidth / slidesToShow) * props.slideWidth;
    }

    if (!props.vertical) {
      slideWidth -= props.cellSpacing * ((100 - 100 / slidesToShow) / 100);
    }

    return { slideHeight, slideWidth };
  }

  setSlideHeightAndWidth = () => {
    setTimeout(() => { this.setState(this.calcSlideHeightAndWidth()) }, 500)
  }

  setDimensions(props, stateCb = () => {}) {
    props = props || this.props;

    const { slidesToShow, cellAlign } = props

    const frame = this.frame;
    const { slideHeight, slideWidth } = this.calcSlideHeightAndWidth(props);

    const frameHeight = slideHeight + props.cellSpacing * (slidesToShow - 1);
    const frameWidth = props.vertical ? frameHeight : frame.offsetWidth;

    let slidesToScroll = props.slidesToScroll

    if (slidesToScroll === 'auto') {
      slidesToScroll = Math.floor(
        frameWidth / (slideWidth + props.cellSpacing)
      );
    }

    this.setState(
      {
        frameWidth,
        slideHeight,
        slidesToScroll,
        slidesToShow,
        slideWidth,
        cellAlign,
        left: props.vertical ? 0 : this.getTargetLeft(),
        top: props.vertical ? this.getTargetLeft() : 0
      },
      () => {
        stateCb();
        this.setLeft();
      }
    );
  }

  getChildNodes() {
    return this.frame.childNodes[0].childNodes;
  }

  setLeft() {
    const newLeft = this.props.vertical ? 0 : this.getTargetLeft();
    const newTop = this.props.vertical ? this.getTargetLeft() : 0;

    if (newLeft !== this.state.left || newTop !== this.state.top) {
      this.setState({
        left: newLeft,
        top: newTop
      });
    }
  }

  renderControls() {
    if (this.props.withoutControls) {
      return null
    } else {
      const controls = []
      const textPrevPosition = MC.getFieldParamValue(this.props.data.param, '@textPrevPosition')
      if (!MC.isNull(textPrevPosition)) {
        const textPrev = MC.getFieldParamValue(this.props.data.param, '@textPrev')
        controls.push(
          <div className="slider-control-text-prev" style={getDecoratorStyles(textPrevPosition)} key="textPrev">
            <PreviousButton {...this.props} cellAlign={this.props.cellAlign} cellSpacing={this.props.cellSpacing} currentSlide={this.state.currentSlide} frameWidth={this.state.frameWidth}
            goToSlide={index => this.goToSlide(index)} nextSlide={() => this.nextSlide()} previousSlide={() => this.previousSlide()} slideCount={this.state.slideCount} slidesToScroll={this.state.slidesToScroll}
            slidesToShow={this.state.slidesToShow} slideWidth={this.state.slideWidth} wrapAround={this.props.wrapAround} textPrev={textPrev} />
          </div>)
      }
      const textNextPosition = MC.getFieldParamValue(this.props.data.param, '@textNextPosition')
      if (!MC.isNull(textNextPosition)) {
        const textNext = MC.getFieldParamValue(this.props.data.param, '@textNext')
        controls.push(
          <div className="slider-control-text-next" style={getDecoratorStyles(textNextPosition)} key="textNext">
            <NextButton {...this.props} cellAlign={this.props.cellAlign} cellSpacing={this.props.cellSpacing} currentSlide={this.state.currentSlide} frameWidth={this.state.frameWidth}
              goToSlide={index => this.goToSlide(index)} nextSlide={() => this.nextSlide()} previousSlide={() => this.previousSlide()} slideCount={this.state.slideCount} slidesToScroll={this.state.slidesToScroll}
              slidesToShow={this.state.slidesToShow} slideWidth={this.state.slideWidth} wrapAround={this.props.wrapAround} textNext={textNext} />
          </div>)  
      }
      const pagingDotsPosition = MC.getFieldParamValue(this.props.data.param, '@pagingDotsPosition')
      if (!MC.isNull(pagingDotsPosition)) {
        controls.push(
          <div className="slider-control-paging-dots" style={getDecoratorStyles(pagingDotsPosition)} key="pagingDots">
            <PagingDots {...this.props} cellAlign={this.props.cellAlign} cellSpacing={this.props.cellSpacing} currentSlide={this.state.currentSlide} frameWidth={this.state.frameWidth}
              goToSlide={index => this.goToSlide(index)} nextSlide={() => this.nextSlide()} previousSlide={() => this.previousSlide()} slideCount={this.state.slideCount} slidesToScroll={this.state.slidesToScroll}
              slidesToShow={this.state.slidesToShow} slideWidth={this.state.slideWidth} wrapAround={this.props.wrapAround} />
          </div>)
      }
      return controls
    }
  }

  render() {
    const {currentSlide, frameWidth} = this.state;
    const {frameOverflow, vertical, framePadding, slidesToShow} = this.props;
    const duration = this.state.dragging || (!this.state.dragging && this.state.resetWrapAroundPosition && this.props.wrapAround) || !this.state.hasInteraction ? 0 : this.props.speed;
    const frameStyles = getFrameStyles(frameOverflow, vertical, framePadding, frameWidth);
    const touchEvents = this.getTouchEvents();
    const mouseEvents = this.getMouseEvents();

    return (
      <div className={['mnc-carousel', this.props.className || ''].join(' ')} style={Object.assign({}, getSliderStyles(this.props.width, this.props.height), this.props.style)}>
        <div className="slider-frame" ref={frame => (this.frame = frame)} style={frameStyles} {...touchEvents} {...mouseEvents} onClickCapture={this.handleClick}>
          <Animate start={{ tx: 0, ty: 0 }}
            update={() => {
              const { tx, ty } = this.getOffsetDeltas();
              if (this.props.disableEdgeSwiping && !this.props.wrapAround && this.isEdgeSwiping()) {
                return {}
              } else {
                return {tx, ty, timing: {duration, ease: this.state.easing},
                  events: {end: () => {
                      const newLeft = this.props.vertical ? 0 : this.getTargetLeft();
                      const newTop = this.props.vertical ? this.getTargetLeft(): 0;
                      if (newLeft !== this.state.left || newTop !== this.state.top) {
                        this.setState({left: newLeft, top: newTop, isWrappingAround: false, resetWrapAroundPosition: true}, () => {
                          this.setState({resetWrapAroundPosition: false})
                        })
                      }
                  }}
                }
              }
            }}
            children={({ tx, ty }) => (
              <ScrollTransition {...getTransitionProps(this.props, this.state)} deltaX={tx} deltaY={ty}>
                {addAccessibility(this.state.items, slidesToShow, currentSlide)}
              </ScrollTransition>
            )}/>
        </div>
        {this.renderControls()}
      </div>
    );
  }
}

Carousel.defaultProps = {
  autoplay: false,
  autoplayInterval: 5000,
  autoplayReverse: false,
  cellAlign: 'left',
  cellSpacing: 0,
  disableEdgeSwiping: false,
  dragging: true,
  frameOverflow: 'hidden',
  framePadding: '0px',
  height: 'auto',
  heightMode: 'max',
  onResize() {},
  pauseOnHover: true,
  slideIndex: 0,
  slideOffset: 25,
  slidesToScroll: 1,
  slidesToShow: 1,
  slideWidth: 1,
  speed: 500,
  style: {},
  swiping: true,
  vertical: false,
  width: '100%',
  withoutControls: false,
  wrapAround: false,
  slideListMargin: 10
};

export default Carousel