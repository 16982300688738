import React from "react"
import {DateTime} from "luxon"

import DaysView from './DaysView.jsx'
import MonthsView from './MonthsView.jsx'
import YearsView from './YearsView.jsx'
import TimeView from './TimeView.jsx'
import {MC} from "../MC.js"

let viewModes = Object.freeze({
  YEARS: 'years',
  MONTHS: 'months',
  DAYS: 'days',
  TIME: 'time',
})

class Datetime extends React.Component {

  allowedSetTime = ['hour', 'minute', 'second', 'millisecond']
  componentProps = {
    fromProps: ['value', 'min', 'max', 'modelerActive', 'weekends'],
    fromState: ['viewDate', 'selectedDate', 'updateOn'],
    fromThis: ['setDate', 'setTime', 'showView', 'addTime', 'subtractTime', 'updateSelectedDate', 'localLuxon', 'isValidDay', 'adjustDateMinMax']
  }
  viewComponents = { days: DaysView, months: MonthsView, years: YearsView, time: TimeView }
  rootRef = React.createRef()

  constructor(props, context) {
    super(props, context)
    let state = this.getStateFromProps(props)
    state.open = !props.input
    state.currentView = props.dateFormat ? (props.viewMode || state.updateOn || viewModes.DAYS) : viewModes.TIME
    this.state = state
  }

  componentDidMount() {
    document.addEventListener('MNC.CLICK', this.handleOutsideClick)
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
    document.removeEventListener('click', this.handleOutsideClick)
  }

  dateFromValue(date) {
    let parsedDate = null
    if (date && typeof date !== 'string') {
      parsedDate = this.localLuxon(date)
      if (parsedDate && !parsedDate.isValid) {
        parsedDate = null
      }
    }
    return parsedDate
  }

  parseViewDate(value) {
    let viewDate = MC.dateTimeStringToLuxon(value).v
    if (viewDate && !viewDate.isValid) {
      viewDate = this.localLuxon()
    }
    return viewDate
  }  

  getStateFromProps(props) {
    let formats = this.getFormats(props)
    let date = props.value || props.defaultValue
    let selectedDate = this.dateFromValue(date)
    let viewDate = this.adjustDateMinMax(this.parseViewDate(props.viewDate))
    if (viewDate && props.locale) {
      viewDate = viewDate.setLocale(props.locale)
    }
    viewDate = selectedDate ? selectedDate.startOf('month') : viewDate.startOf('month')
    let updateOn = this.getUpdateOn(formats)
    let inputValue
    if (selectedDate) {
      inputValue = selectedDate.toFormat(this.state && this.state.inputFormat ? this.state.inputFormat : formats.datetime)
    } else if (!date.isValid) {
      inputValue = date || ''
    }
    return {
      updateOn: updateOn,
      inputFormat: this.state && this.state.inputFormat ? this.state.inputFormat : formats.datetime,
      viewDate: viewDate,
      selectedDate: selectedDate,
      inputValue: inputValue
    }
  }

  getUpdateOn(formats) {
    if (formats.date.match(/[d]/)) {
      return viewModes.DAYS
    } else if (formats.date.indexOf('M') !== -1) {
      return viewModes.MONTHS
    } else if (formats.date.indexOf('y') !== -1) {
      return viewModes.YEARS
    }
    return viewModes.DAYS
  }

  getFormats(props) {
    let formats = { date: props.dateFormat || '', time: props.timeFormat || '' }
    if (this.getUpdateOn(formats) !== viewModes.DAYS) {
      formats.time = ''
    }
    formats.datetime = formats.date && formats.time ? formats.date + ' ' + formats.time : formats.date || formats.time
    return formats
  }

  componentDidUpdate(prevProps) {
    if (!MC.objectEqual(prevProps, this.props, true)) {
      let nextProps = this.props
      var formats = this.getFormats(nextProps),
        updatedState = {}
      if (nextProps.value !== prevProps.value || formats.datetime !== this.getFormats(prevProps).datetime) {
        updatedState = this.getStateFromProps(nextProps)
      }
      if (nextProps.viewMode !== prevProps.viewMode) {
        updatedState.currentView = nextProps.viewMode
      }
      if (nextProps.utc !== prevProps.utc || nextProps.displayTimeZone !== prevProps.displayTimeZone) {
        if (nextProps.utc) {
          if (this.state.viewDate)
            updatedState.viewDate = this.state.viewDate.toUTC()
          if (this.state.selectedDate) {
            updatedState.selectedDate = this.state.selectedDate.toUTC()
            updatedState.inputValue = updatedState.selectedDate.toFormat(this.state.inputFormat)
          }
        } else if (nextProps.displayTimeZone) {
          if (this.state.viewDate)
            updatedState.viewDate = this.state.viewDate.setZone(nextProps.displayTimeZone)
          if (this.state.selectedDate) {
            updatedState.selectedDate = this.state.selectedDate.setZone(nextProps.displayTimeZone)
            updatedState.inputValue = updatedState.selectedDate.setZone(nextProps.displayTimeZone).toFormat(this.state.inputFormat)
          }
        } else {
          if (this.state.viewDate)
            updatedState.viewDate = this.state.viewDate.toLocal()
          if (this.state.selectedDate) {
            updatedState.selectedDate = this.state.selectedDate.toLocal()
            updatedState.inputValue = updatedState.selectedDate.toFormat(this.state.inputFormat)
          }
        }
      }
      if (nextProps.viewDate !== prevProps.viewDate) {
        updatedState.viewDate = this.parseViewDate(nextProps.viewDate)
      }
      this.setState(updatedState)

    }
  }

  onInputChange = (e) => {
    let value = e.target === null ? e : e.target.value
    let localLuxon = this.localLuxon(value, this.state.inputFormat)
    if (this.props.altFormats && Array.isArray(this.props.altFormats) && this.props.altFormats.length > 0) {
      let i = 0
      while (!localLuxon.isValid && i<this.props.altFormats.length) {
        let formats = this.getFormats(this.props.altFormats[i])
        localLuxon = this.localLuxon(value, formats.datetime)
        if (localLuxon.isValid) {
          this.setState({inputFormat: formats.datetime})
        }
        i++
      }
    }
    let update = {inputValue: value}
    if (value && localLuxon.isValid && !this.props.value) {
      update.selectedDate = localLuxon
      update.viewDate = localLuxon.startOf('month')
    } else {
      update.selectedDate = null
    }
    return this.setState(update, function () {
      return this.props.onChange(value && localLuxon.isValid ? localLuxon : this.state.inputValue)
    })
  }

  onInputKey = (e) => {
    if (e.which === 9 && this.props.closeOnTab) {
      this.closeCalendar()
    }
  }

  showView = (view) => {
    var me = this
    return function () {
      me.state.currentView !== view && me.props.onViewModeChange(view)
      me.setState({ currentView: view })
    }
  }

  setDate = (type) => {
    let me = this
    let  nextViews = {month: viewModes.DAYS, year: viewModes.MONTHS}
    return function (e) {
      let toSet = {}
      toSet[type] = parseInt(e.target.getAttribute('data-value'), 10)
      me.setState({
        viewDate: me.state.viewDate.set(toSet).startOf(type),
        currentView: nextViews[type]
      })
      me.props.onViewModeChange(nextViews[type])
    }
  }

  subtractTime = (amount, type, toSelected) => {
    let me = this
    return function () {
      me.props.onNavigateBack(amount, type)
      me.updateTime('minus', amount, type, toSelected)
    }
  }

  addTime = (amount, type, toSelected) => {
    let me = this
    return function () {
      me.props.onNavigateForward(amount, type)
      me.updateTime('plus', amount, type, toSelected)
    }
  }

  updateTime = (op, amount, type, toSelected) => {
    let update = {}
    let date = toSelected ? 'selectedDate' : 'viewDate'
    let toSet = {}
    toSet[type] = amount
    update[date] = this.state[date][op](toSet)
    this.setState(update)
  }

  setTime = (type, value, date) => {
    let index = this.allowedSetTime.indexOf(type) + 1
    let state = this.state
    let nextType
    // It is needed to set all the time properties
    // to not to reset the time
    let toSet = {}
    toSet[type] = value
    date = date.set(toSet)
    for (; index < this.allowedSetTime.length; index++) {
      nextType = this.allowedSetTime[index]
      toSet = {}
      toSet[nextType] = date.nextType
      date = date.set(toSet)
    }
    if (!this.props.value) {
      this.setState({
        selectedDate: date,
        inputValue: date.toFormat(state.inputFormat)
      })
    }
    this.props.onChange(date)
  }

  updateSelectedDate = (e, close, viewDate) => {
    let target = e.currentTarget
    let modifier = 0
    let currentDate = this.state.selectedDate || viewDate
    let date
    if (target.className.indexOf('rdtDay') !== -1) {
      if (target.className.indexOf('rdtNew') !== -1)
        modifier = 1
      else if (target.className.indexOf('rdtOld') !== -1)
        modifier = -1
      date = viewDate.set({month: viewDate.month + modifier, day: parseInt(target.getAttribute('data-value'), 10)})
    } else if (target.className.indexOf('rdtMonth') !== -1) {
      date = viewDate.set({month: parseInt(target.getAttribute('data-value'), 10), day: currentDate.day})
    } else if (target.className.indexOf('rdtYear') !== -1) {
      date = viewDate.set({month: currentDate.month, day: currentDate.day, year: parseInt(target.getAttribute('data-value'), 10)})
    }
    date.set({hour: currentDate.hour, minute: currentDate.minute, second: currentDate.second, millisecond: currentDate.millisecond})
    if (this.props.closeOnSelect && close) {
      this.closeCalendar()
    }
    this.props.onChange(date)
  }

  openCalendar = (e) => {
    if (!this.state.open) {
      this.setState({open: true}, function () {
        this.setOpenDirection()
      })
    }
  }

  setOpenDirection = () => {
    const calendar = this.rootRef.current.querySelector('.rdtPicker')
    if (!calendar) return
    const calWidth = calendar.clientWidth
    const calendarRect = calendar.getBoundingClientRect()
    let rootNode = document.documentElement
    let table = MC.findAncestorEl(this.rootRef.current, 'table')
    if (table && table.parentNode.classList.contains('scrollable-horizontal')) {
      rootNode = table
    }
    const spaceAtTheRight = rootNode.clientWidth - calendarRect.left - calWidth
    const spaceAtTheLeft = calendarRect.left - calWidth
    const toLeft = spaceAtTheRight < 0 && spaceAtTheLeft > spaceAtTheRight
    if (!toLeft !== !this.state.toLeft) {
      this.setState({toLeft})
    }
  }

  closeCalendar = () => {
    this.setState({open: false, toLeft: false})
  }

  handleOutsideClick= (e) => {
    const node = this.rootRef.current
    if (node && node.contains(e.detail.target) || (e.detail.target && e.detail.target.className && e.detail.target.className.indexOf('rdt') > -1)) {
      return
    }
    if (this.props.input && this.state.open && this.props.open === undefined && !this.props.disableCloseOnClickOutside) {
      this.setState({open: false, toLeft: false})
    }
  }

  localLuxon = (date, format, props) => {
    props = props || this.props
    let l = null
    if (!date || !date.isLuxonDateTime) {
      if (date) {
        if (props.displayTimeZone) {
          l = DateTime.fromFormat(date, format, { setZone: true })
        } else {
          l = DateTime.fromFormat(date, format)
        }
      } else {
        l = DateTime.local()
      }
    } else {
      l = date
    }
    if (props.locale) {
      l = l.setLocale(props.locale)
    }
    return l
  }

  getComponentProps() {
    let me = this
    let formats = this.getFormats(this.props)
    let props = { dateFormat: formats.date, timeFormat: formats.time }
    this.componentProps.fromProps.forEach(function (name) {
      props[name] = me.props[name]
    })
    this.componentProps.fromState.forEach(function (name) {
      props[name] = me.state[name]
    })
    this.componentProps.fromThis.forEach(function (name) {
      props[name] = me[name]
    })
    return props
  }

  overrideEvent = (handler, action) => {
    if (!this.overridenEvents) {
      this.overridenEvents = {}
    }
    if (!this.overridenEvents[handler]) {
      var me = this
      this.overridenEvents[handler] = function (e) {
        var result
        if (me.props.inputProps && me.props.inputProps[handler]) {
          result = me.props.inputProps[handler](e)
        }
        if (result !== false) {
          action(e)
        }
      }
    }
    return this.overridenEvents[handler]
  }

  isValidDay = (date) => {
    if (this.props.modelerActive) {
      return true
    }
    return MC.isValidDay(date, this.props.min, this.props.max, this.props.weekends) ? 1 : 0
  }

  adjustDateMinMax = (date) => {
    if (this.props.max) {
      let maxLuxon = MC.dateTimeStringToLuxon(this.props.max).v
      if (maxLuxon.isValid && maxLuxon.startOf('day') < date.startOf('day')) {
        date = date.set({year: maxLuxon.year, month: maxLuxon.month, day: maxLuxon.day})
      }
    }
    if (this.props.min) {
      let minLuxon = MC.dateTimeStringToLuxon(this.props.min).v
      if (minLuxon.isValid && minLuxon.startOf('day') > date.startOf('day')) {
        date = date.set({year: minLuxon.year, month: minLuxon.month, day: minLuxon.day}) 
      }  
    }
    return date
  }

  render() {
    let className = MC.classes('rdt', this.props.className)
    let input = null
    if (this.props.input) {
      var finalInputProps = Object.assign(
        {type: 'text', className: 'form-control', value: this.state.inputValue, autoComplete: this.props.hideCalendar ? 'on' : 'off'},
        this.props.inputProps,
        {
          onClick: !this.props.hideCalendar && !this.props.readOnly ? this.overrideEvent('onClick', this.openCalendar) : null,
          onFocus: !this.props.hideCalendar && !this.props.readOnly ? this.overrideEvent('onFocus', this.openCalendar) : null,
          onChange: this.overrideEvent('onChange', this.onInputChange),
          onKeyDown: this.overrideEvent('onKeyDown', this.onInputKey)
        }
      )
      input = <input key="i" ref={this.props.widgetRef} {...finalInputProps}/>
    } else {
      className += ' rdtStatic'
    }
    let calendar = null
    if (this.props.open || (this.props.open === undefined && this.state.open)) {
      className += ' rdtOpen'
      let CurrentView = this.viewComponents[this.state.currentView]
      calendar = <div key="dt" className={MC.classes('rdtPicker', {'toLeft': this.state.toLeft})}><CurrentView {...this.getComponentProps()}/></div>
    }
    return <div className={className} ref={this.rootRef}> {input}{calendar}</div>
  }
  
}

Datetime.defaultProps = {
  className: '',
  defaultValue: '',
  inputProps: {},
  input: true,
  onChange: function () { },
  onViewModeChange: function () { },
  onNavigateBack: function () { },
  onNavigateForward: function () { },
  timeFormat: true,
  dateFormat: true,
  closeOnSelect: false,
  closeOnTab: true,
  utc: false
}

export default Datetime