import React from "react"
import {MC} from "../MC.js"

class DateTimePickerTime extends React.Component {

  padValues = { hour: 1, minute: 2, second: 2, millisecond: 3 }
  state = this.calculateState(this.props)
  timeConstraints = {
    hour: {
      min: 0,
      max: 23,
      step: 1
    },
    minute: {
      min: 0,
      max: 59,
      step: 1
    },
    second: {
      min: 0,
      max: 59,
      step: 1
    },
    millisecond: {
      min: 0,
      max: 999,
      step: 1
    }
  }

  calculateState(props) {
    let date = props.selectedDate || props.viewDate
    if (props.max) {
      let maxLuxon = MC.dateTimeStringToLuxon(props.max).v
      if (maxLuxon.isValid && maxLuxon < date) {
        date = date.set({hour: maxLuxon.hour, minute: maxLuxon.minute, second: maxLuxon.second, milliseconds: maxLuxon.milliseconds}) 
      }
    }
    if (props.min) {
      let minLuxon = MC.dateTimeStringToLuxon(props.min).v
      if (minLuxon.isValid && minLuxon > date) {
        date = date.set({hour: minLuxon.hour, minute: minLuxon.minute, second: minLuxon.second, milliseconds: minLuxon.milliseconds}) 
      }  
    }
    let format = props.timeFormat
    let counters = []
    if (format.toLowerCase().indexOf('h') !== -1) {
      counters.push('hour')
      if (format.indexOf('m') !== -1) {
        counters.push('minute')
        if (format.indexOf('s') !== -1) {
          counters.push('second')
        }
      }
    }
    let hour = date.toFormat('H')
    let daypart = false
    if (this.state !== null && this.props.timeFormat.indexOf(' a') !== -1) {
      daypart = (hour >= 12) ? 'PM' : 'AM'
    }
    return {
      hour: hour,
      minute: date.toFormat('mm'),
      second: date.toFormat('ss'),
      millisecond: date.millisecond,
      daypart: daypart,
      counters: counters,
      adjustedViewDate: date 
    }
  }

  renderCounter(type) {
    if (type !== 'daypart') {
      let value = this.state[type]
      if (type === 'hour' && this.props.timeFormat.toLowerCase().indexOf(' a') !== -1) {
        value = (value - 1) % 12 + 1
        if (value === 0) {
          value = 12
        }
      }
      return (
        <div key={type} className="rdtCounter">
          <span key="up" className="rdtBtn" onMouseDown={this.onStartClicking('increase', type)} onContextMenu={this.disableContextMenu}>▲</span>
          <div key="c" className="rdtCount">{value}</div>
          <span key="do" className="rdtBtn" onMouseDown={this.onStartClicking('decrease', type)} onContextMenu={this.disableContextMenu}>▼</span>
        </div>)
    }
    return ''
  }

  renderDayPart() {
    return (<div key="dayPart" className="rdtCounter">
      <span key="up" className="rdtBtn" onMouseDown={this.onStartClicking('toggleDayPart', 'hour')} onContextMenu={this.disableContextMenu}>▲</span>
      <div key={this.state.daypart} className="rdtCount">{this.state.daypart}</div>
      <span key="do" className="rdtBtn" onMouseDown={this.onStartClicking('toggleDayPart', 'hour')} onContextMenu={this.disableContextMenu}>▼</span>
    </div>)
  }

  render() {
    let me = this
    let counters = []
    this.state.counters.forEach(function (c) {
      if (counters.length) {
        counters.push(<div key={'sep' + counters.length} className="rdtCounterSeparator">:</div>)
      }
      counters.push(me.renderCounter(c))
    })
    if (this.state.daypart !== false) {
      counters.push(me.renderDayPart())
    }
    if (this.state.counters.length === 3 && this.props.timeFormat.indexOf('S') !== -1) {
      counters.push(<div className="rdtCounterSeparator" key="sep5">:</div>)
      counters.push(
        <div className="rdtCounter rdtMilli" key="m">
          <input value={this.state.millisecond} type="text" onChange={this.updateMilli} />
        </div>
      )
    }
    return (
      <div className="rdtTime">
        <table>
          {this.renderHeader()}
          <tbody key="b"><tr><td><div className="rdtCounters">{counters}</div></td></tr></tbody>
        </table>
      </div>)
  }

  componentDidUpdate(prevProps) {
    if (!MC.objectEqual(prevProps, this.props, true)) {
      this.setState(this.calculateState(this.props))
    }
  }

  updateMilli = (e) => {
    let milli = parseInt(e.target.value, 10)
    if (milli == e.target.value && milli >= 0 && milli < 1000) {
      this.props.setTime('millisecond', milli, this.state.adjustedViewDate)
      this.setState({ millisecond: milli })
    }
  }

  renderHeader() {
    if (!this.props.dateFormat)
      return null
    let date = this.props.selectedDate || this.props.viewDate
    return <thead key="h">
      <tr>
        <th className="rdtSwitch" colSpan={4} onClick={this.props.showView('days')}>{date.toFormat(this.props.dateFormat)}</th>
      </tr>
    </thead>
  }

  onStartClicking = (action, type) => {
    var me = this

    return function () {
      var update = {}
      update[type] = me[action](type)
      if (action == 'increase') {
        if (me.props.max) {
          let maxLuxon = MC.dateTimeStringToLuxon(me.props.max).v
          if (maxLuxon.isValid) {
            let adjustedTime = me.state.adjustedViewDate.set(update)
            if (maxLuxon < adjustedTime) {
              return
            }
          }
        }
      } else {
        if (me.props.min) {
          let minLuxon = MC.dateTimeStringToLuxon(me.props.min).v
          if (minLuxon.isValid) {
            let adjustedTime = me.state.adjustedViewDate.set(update)
            if (minLuxon > adjustedTime) {
              return
            }
          }  
        }
      }
      me.setState(update)
      me.timer = setTimeout(function () {
        me.increaseTimer = setInterval(function () {
          update[type] = me[action](type)
          me.setState(update)
        }, 70)
      }, 500)
      me.mouseUpListener = function () {
        clearTimeout(me.timer)
        clearInterval(me.increaseTimer)
        me.props.setTime(type, me.state[type], me.state.adjustedViewDate)
        document.body.removeEventListener('mouseup', me.mouseUpListener)
        document.body.removeEventListener('touchend', me.mouseUpListener)
      }
      document.body.addEventListener('mouseup', me.mouseUpListener)
      document.body.addEventListener('touchend', me.mouseUpListener)
    }
  }

  disableContextMenu(event) {
    event.preventDefault()
    return false
  }

  toggleDayPart = (type) => { // type is always 'hour'
    let value = parseInt(this.state[type], 10) + 12
    if (value > this.timeConstraints[type].max)
      value = this.timeConstraints[type].min + (value - (this.timeConstraints[type].max + 1))
    return this.pad(type, value)
  }

  increase = (type) => {
    let value = parseInt(this.state[type], 10) + this.timeConstraints[type].step
    if (value > this.timeConstraints[type].max) {
      this.timeConstraints[type].max
    }
    return this.pad(type, value)
  }

  decrease = (type) => {
    let value = parseInt(this.state[type], 10) - this.timeConstraints[type].step
    if (value < this.timeConstraints[type].min) {
      value = this.timeConstraints[type].min
    }
    return this.pad(type, value)
  }

  pad = (type, value) => {
    let str = value + ''
    while (str.length < this.padValues[type])
      str = '0' + str
    return str
  }

}

export default DateTimePickerTime