import React from "react"

import {MC} from "../client/MC.js";
import {Form} from "../client/Form.jsx";
import {ReactFlow} from "../client/ReactFlow.jsx";

import {Clipboard} from "./Clipboard.js";
import {Inspector} from "./Inspector.jsx";
import {Palette} from "./Palette.jsx";
import {FieldDef} from "./FieldDef.js";
import {WidgetModel} from "./WidgetModel.js";
import {Menu} from "./Menu.jsx";
import {addEmptyRbsId} from './library'

class Modeler extends React.Component {

  state = {undoStack: [],
    redoStack: [],
    savedPosition: 0,
    ghostMode: true,
    structuralMode: true,
    selectedFieldIds: [],
    form: this.props.form,
    active: true,
    widgetModelLoaded: false,
    formDefinitionLoaded: false,
    resolution: MC.getAvailableResolution("medium", this.props.form) || "medium",
    fieldPalette: null,
    showPalette: false,
    showInspector: false,
    palettePosition: null,
    inspectorPosition: null,
    interactiveField: null};
  modelerRef = React.createRef()  

    constructor(props) {
      super(props)
      FieldDef.setProto(props.form)
      props.form.setParents()      
      document.body.style.overflow = "scroll"
      let self = this
      document.addEventListener("keydown", function (event) {
        if (!self.state.showInspector && !self.state.showPalette) {
          self.handleKeyDown(event)
        } else if (event.key == "Enter" && event.target.nodeName != 'TEXTAREA' || event.key == "Escape") {
          self.cancelModal()
        }
      })
      this.props.form.flow.isModelerActive = true
    }

    handleKeyDown = (event) => {
      let isCtrl = event.ctrlKey || event.metaKey
      if (event.key == "Delete" || event.key == "Backspace") {
        Clipboard.deleteSelectedFields(this)
      }
      if (event.code == "KeyA" && isCtrl) {
        event.preventDefault()
        this.setSelectedFields(this.state.form.getChildFieldIds())
      }
      if (event.code == "KeyZ" && isCtrl) {
        this.handleUndo()
      }
      if (event.code == "KeyY" && isCtrl) {
        this.handleRedo()
      }
      if (event.code == "KeyL" && isCtrl) {
        event.preventDefault()
        this.store(this.state.form)
        this.selectedFields().forEach(function(field) {
          let newLineAfter = field.getOption(["grid", "newLineAfter"]);
          field.setOption(["grid", "newLineAfter"], newLineAfter == "yes" ? "never" : "yes")
        })
        this.forceUpdate()
        this.formWasChanged()
      }
      if (event.code == "KeyS" && event.shiftKey) {
        if (this.isSavePossible()) {
          this.handleSave()
        }
      }
      if (event.code == "KeyC" && isCtrl) {
        Clipboard.copySelectedFields(this)
      }
      if (event.code == "KeyV" && isCtrl) {
        Clipboard.pasteFieldsFromClipboard(this)
      }
      if (event.code == "KeyX" && isCtrl) {
        Clipboard.copySelectedFields(this)
        Clipboard.deleteSelectedFields(this)
      }
    }

    componentDidUpdate() {
      let isSavePossible = !this.props.readOnly &&  !this.isSaved()
      modelerChanged(isSavePossible)
      if (this.resize) {
        this.resize = false
        modelerChanged("resize")
      }
    }

    closeError = () => {
      this.setState({errorMessage: null})
    }

    isSavePossible = () => {
      return !this.props.readOnly && !this.isSaved();
    };

    isSaved = () => {
      return this.state.savedPosition == this.state.undoStack.length;
    };

    componentDidMount() {
      var self = this;
      this.state.form.flow.modelerReact = this;
      this.resize = true;
      FieldDef.form = this.state.form;
      FieldDef.modeler = this;
      this.isDragPossible = true;
      WidgetModel.load(this.state.form.model, this.state.form.flow.lang, this.props.mconf).then(function(){
        self.setState({widgetModelLoaded: true});
      });

      this.getFormDefinition().then(function(definition) {
        self.state.form.setDefinition(definition);
        self.setState({formDefinitionLoaded: true});
      })

      document.addEventListener("mouseup", this.handleMouseup)
      document.addEventListener("click", this.handleDocumentClick)
      if (parent) {
        parent.document.addEventListener("click", this.handleDocumentClick)
      }

      modelerChanged("loaded")
    }

    handleDocumentClick = (e) => {
      let cont = document.querySelector('#modelerContainer')
      if (cont != e.target && !cont.contains(e.target)) {
        this.handleClick()
      }
    }

    handleMouseup = () => {
      this.fieldMouseDown = null
    }

    componentWillUnmount() {
      delete this.state.form.flow.modelerReact;
      delete this.state.form.flow.isModelerActive;
      document.removeEventListener("mouseup", this.handleMouseup)
      document.removeEventListener("keydown", this.handleKeyDown)
      document.removeEventListener("click", this.handleDocumentClick)
      if (parent) {
        parent.document.removeEventListener("click", this.handleDocumentClick)
      }
    }

    handleClick = (event) => {
      this.setState({selectedFieldIds: [], showPalette: false, showInspector: false});
    };

    store = (form) => {
      var formCopy = form.getCopy();
      this.setState({undoStack: this.state.undoStack.concat([formCopy]),
                     redoStack: []});
      this.resize = true;
    };

    getFormCopy = () => {
      return this.state.form.getCopy();
    };

    setForm = (form) => {
      FieldDef.setProto(form);
      form.setParents()
      this.setState({form: form});
    };

    formWasChanged = () => {
      this.setForm(this.state.form);
    };

    setSelectedField = (fieldId) => {
      let inspectorTop = null
      let scrollY = window.scrollY
      let innerModelerTop = MC.offset(document.querySelector("#innerModeler")).top
      if (scrollY > innerModelerTop) {
        inspectorTop = scrollY - innerModelerTop
      }
      this.setState({selectedFieldIds: [fieldId], inspectorTop: inspectorTop})
    }

    setSelectedFields = (fieldIds) => {
      this.setState({selectedFieldIds: fieldIds});
    };

    tongueSelectedFieldId = (fieldId) => {
      var selectedFieldIds = this.state.selectedFieldIds;
      if (selectedFieldIds.indexOf(fieldId) == -1) {
        this.setState({selectedFieldIds: selectedFieldIds.concat([fieldId])});
      } else {
        this.setState({selectedFieldIds: selectedFieldIds.filter(function(id) {return id != fieldId})});
      }
    };

    selectedFields = () => {
      var form = this.state.form;
      var selectedFieldIds = this.state.selectedFieldIds;
      return selectedFieldIds.map(function(fieldId) {
        return form.findFieldByRbsId(fieldId);
      }).filter(function(field) {
        return field;
      });
    };

    handleUndo = () => {
      var undoStack = this.state.undoStack;
      if (undoStack.length != 0) {
        var redoStack = this.state.redoStack;
        var form = this.state.form;
        this.resize = true;
        this.setForm(undoStack[undoStack.length - 1])
        this.setState({undoStack: undoStack.slice(0,-1),
                       redoStack: redoStack.concat([form.getCopy()])});
      }
    };

    handleRedo = () => {
      var redoStack = this.state.redoStack;
      if (redoStack.length != 0) {
        var undoStack = this.state.undoStack;
        var form = this.state.form;
        this.resize = true;
        this.setForm(redoStack[redoStack.length - 1]);
        this.setState({redoStack: redoStack.slice(0,-1),
                       undoStack: undoStack.concat([form.getCopy()])})
      }
    };

    cancel = () => {
      this.setState({undoStack: this.state.undoStack.slice(0,-1)});
    };

    resetStacks = () => {
      this.setState({undoStack: [], redoStack: [], savedPosition: 0});
    };

    setErrorMessage = (errorMessage) => {
      this.setState({errorMessage: errorMessage});
    };

    handleSave = () => {
      var self = this;
      var form = this.state.form;
      this.setState({saveInProcess: true});
      this.getFormDefinition().then(function (formdef) {
        if (formdef) {
          var def = form.getDefinition();
          formdef.FormField = addEmptyRbsId(def.FormField);
          console.log(formdef); //JSON.stringify(formdef, null, 4));
          self.saveFormDefinition(formdef).then(function() {
            self.setState({savedPosition: self.state.undoStack.length,
                           saveInProcess: false});
            form.setToStored();
            self.formWasChanged();
          }).catch(function(err) {
            console.log(err);
            self.setErrorMessage("Chyba ukládání");
            self.setState({saveInProcess: false});
          });
        } else {
            self.setErrorMessage("Chyba ukládání");
            self.setState({saveInProcess: false});
        }

      }).catch(function(err) {
          console.log(err);
          self.setErrorMessage("Chyba ukládání");
          self.setState({saveInProcess: false});
      });
    };

    getFormDefinition = () => {
      let form = this.state.form
      return MC.callServer('GET', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_FormGet?inputdata=' + encodeURIComponent(JSON.stringify({model: form.model, formid: form.formId})), MC.getJsonType()).then(function (res) {
        let def = JSON.parse(res.content).properties
        WidgetModel.ensureArray(def, ['FormField', 'FormFieldGrid', 'FormFieldParam'])
        return def
      })
    }

    saveFormDefinition = (formdef) => {
      let form = this.state.form
      let self = this
      let body = {model: form.model, formid: form.formId, definition: formdef}
      return MC.callServer('POST', this.props.mconf.baseUrl + ReactFlow.flowServerUrl + 'miniclientcfg/API_FormUpdate?loggingthreshold=DETAIL', '*/*', JSON.stringify(body), MC.getJsonType()).then(function(res) {
        if (res.status !== 200 && res.status !== 204) {
          console.log(res.content)
          self.setErrorMessage("Chyba ukládání")
          self.setState({saveInProcess: false})
        }
      }).catch(function(err) {
        console.log(err)
        self.setErrorMessage("Chyba ukládání")
        self.setState({saveInProcess: false})
      })
    }

    handleTongueGhostMode = () => {
      this.setState({ghostMode: !this.state.ghostMode});
    };

    tongueStructuralMode = () => {
      this.setState({structuralMode: !this.state.structuralMode});
    };

    handleChangeResolution = (resolution) => {
      this.resize = true;
      this.setState({resolution: resolution});
    };

    handleDeleteGrid = () => {
      this.store(this.state.form);
      this.state.form.deleteGrid(this.state.resolution);
      this.formWasChanged();
    };

    handleCreateGrid = () => {
      this.store(this.state.form);
      this.state.form.ensureGrid(this.state.resolution);
      this.state.form.addGrid(this.state.resolution);
      this.formWasChanged();
    };

    handleContextMenu = (event) => {
      event.preventDefault()
      let offset = MC.offset(this.modelerRef.current)
      this.setState({showPalette: true, showInspector: false, palettePosition: {x: event.pageX - offset.left, y: event.pageY - offset.top}})
    }

    showInspector = (position) => {
      this.setState({showInspector: true,
                     showPalette: false,
                     inspectorPosition: position});
    };

    handleCancelModal = (event) => {
      event.preventDefault()
      this.cancelModal()
    }

    cancelModal = () => {
      this.setState({showPalette: false, showInspector: false})
    }

    showPalette = () => {
      this.setState({showPalette: true, palettePosition: {x: 10, y: 80}});
    };

    handleDeleteSelectedFields = () => {
      Clipboard.deleteSelectedFields(this)
    }

  render() {
    let self = this
    let width
    if (this.state.resolution == "x-small") {
      width = 576
    } else if (this.state.resolution == "small") {
      width = 768
    } else if (this.state.resolution == "medium") {
      width = 992
    } else {
      width = 1400
    } 
    let isRedoStack = this.state.redoStack.length != 0;
    let isUndoStack = this.state.undoStack.length != 0;
    let isSavePossible = !this.props.readOnly && !this.isSaved()
    let definedResolutions = ["x-small", "small", "medium", "large"].filter(function(resolution) {
      return MC.hasLayout(self.state.form, resolution)
    })
    let palette
    let inspector
    let modal
    let container = document.querySelector("#modeler .modelerContainer")
    let cheight = container ? parseFloat(getComputedStyle(container, null).height.replace("px", "")) : 0
    let cwidth = container ? parseFloat(getComputedStyle(container, null).width.replace("px", "")) : 0
    let modalStyle = {zIndex: 2, position: 'fixed', display: 'block', left: 0, top: 0, width: '100%', height: '100%'}
    if (this.state.showPalette) {
      let position = {x: Math.min(this.state.palettePosition.x, cwidth - 630), y: Math.min(this.state.palettePosition.y, cheight - 540)}
      palette = <Palette position={position} flow={this.state.form.flow} widgetModelLoaded={this.state.widgetModelLoaded}/>;
      modal = <div style={modalStyle} onClick={this.handleCancelModal} onContextMenu={this.handleCancelModal}/>
    }
    if (this.state.showInspector) {
      let position = {x: Math.min(this.state.inspectorPosition.x, cwidth - 850), y: Math.min(this.state.inspectorPosition.y, cheight - 700)}
      inspector = <Inspector position={position} model={this.state.form.model} top={this.state.inspectorTop} loaded={this.state.widgetModelLoaded && this.state.formDefinitionLoaded} inspectedFields= {this.selectedFields()} onSetSelectedField={this.setSelectedField}
                    onDeleteSelectedFields={this.handleDeleteSelectedFields} onSetForm={this.setForm} onStoreForm={this.store} onGetFormCopy={this.getFormCopy} onFormWasChanged={this.formWasChanged} onCancelChange={this.cancel} mconf={this.props.mconf}/>
      modal = <div style={modalStyle} onClick={this.handleCancelModal}  onContextMenu={this.handleCancelModal}/>
    }
    let errorMessage
    if (this.state.errorMessage) {
      errorMessage = <div className="mc negative message"><i className="close icon" onClick={this.closeError} /><div className="header">Chyba ukládání</div></div>
    }
    return (
      <div id="modeler" style={{display: 'flex', flexDirection: 'column', flexGrow: 1, userSelect: 'none'}} ref={this.modelerRef}>
        {errorMessage}
        <Menu onUndo={this.handleUndo} onRedo={this.handleRedo} onSave={this.handleSave} onTongueGhostMode={this.handleTongueGhostMode} tongueStructuralMode={this.tongueStructuralMode} onChangeResolution={this.handleChangeResolution}
          onDeleteGrid={this.handleDeleteGrid} onCreateGrid={this.handleCreateGrid} resolution={this.state.resolution} setSelectedFields={this.setSelectedFields} form={this.state.form} definedResolutions={definedResolutions}
          isSavePossible={isSavePossible} saveInProcess={this.state.saveInProcess} isUndoStack={isUndoStack} isRedoStack={isRedoStack} ghostMode={this.state.ghostMode} modelerState={this.state} modelerSetState={this.setState} showPalette={this.showPalette}/>
        {palette}
        {inspector}
        {modal}
        <div className="modelerContainer" style={{display: 'flex', flexDirection: 'column', overflowX: 'auto', flexGrow: 1}} onContextMenu={this.handleContextMenu}>
          <div style={{width: width, display: 'flex', flexDirection: 'column', flexGrow: 1}} id="innerModeler" onClick={this.handleClick}>
            <Form form={this.state.form} element={document.getElementById("content-modeler")} embedded={this.props.embedded} mconf={this.props.mconf}/>
          </div>
        </div>
      </div>)
  }
}

export {Modeler}