import React from "react";

import {MC} from "./MC.js";
import {MCHistory} from "./MCHistory.js";
import {Widget} from "./Widget.jsx";

class Form extends React.Component {

  state = {width: this.props.element.offsetWidth};
  serverEvents = {}
  worker = null
  formRef = React.createRef()
  ticking = false

  componentDidMount() {
    window.addEventListener("message", this.onMessage)
    window.addEventListener("resize", this.runResize)
    if (MC.hasLogicForEvent(this.props.form, "scroll")) {
      window.addEventListener("scroll", this.runScroll, {passive: true})
    }
    this.runReadyConditional()
    if (this.props.element.offsetWidth != this.state.width && !this.props.stopRender) {
      this.setState({width: this.props.element.offsetWidth})
    }
    this.props.form.reactForm = this
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.stopRender !== true
  }

  handleSubmitDefault = (e) => {
    e.preventDefault()
  }

  closeFeedback(i) {
    this.props.form.feedback.splice(i, 1);
    this.forceUpdate();
  }

  runReadyConditional() {
    if (MC.isModelerActive(this.props.form)) {
      return;
    }
    if (this.props.form.flow.getRootFlow().runReady) {
      this.props.form.flow.getRootFlow().runReady = false
      if (MC.isFunction(this.props.form.flow.eventForm)) {
        this.props.form.flow.eventForm(null, 'ready')
      }
      var urlarr = window.location.href.split("/");
      window.postMessage({name: 'MNC-INTERNAL.READY-RUN', instanceId: this.props.form.flow.instanceId}, urlarr[0] + "//" + urlarr[2]);
      this.props.form.flow.initLogicTimers();
      this.unregisterServerEvents();
      this.registerServerEvents();
    }
    if (MC.isFunction(this.props.form.flow.afterRenderForm)) { // this should be called after every rerender
      this.props.form.flow.afterRenderForm(this.props.form);
    }
  }

  componentWillUnmount() {
    this.props.form.flow.clearLogicTimers()
    window.removeEventListener("resize", this.runResize)
    window.removeEventListener("message", this.onMessage)
    if (MC.hasLogicForEvent(this.props.form, "scroll")) {
      window.removeEventListener("scroll", this.runScroll, {passive: true})
    }
    this.unregisterServerEvents()
  }

  onMessage = (e) => {
    if (e.data && MC.isPlainObject(e.data)) {
      var name = e.data.name;
      if (!MC.isNull(name) && name != '') {
        if (MC.isFunction(this.props.form.flow.eventForm)) {
          this.props.form.flow.eventForm(null, name, null, e.data);
        }
      }
    }
  };

  runResize = () => {
    this.setState({width: this.props.element.offsetWidth})
    this.props.form.flow.eventForm(null, 'resize')
  }

  runScroll = () => {
    let self = this
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        self.props.form.flow.eventForm(null, 'scroll')
        this.ticking = false
      })
      this.ticking = true
    }
  }

  componentDidUpdate() {
    this.runReadyConditional()
  }

  getResolution(field) {
    if (MC.isModelerReactAvailable(field)) {
      var modelerReact = MC.getModelerReact(field);
      return modelerReact.state.resolution;
    }
    return MC.getResolutionFromWidth(this.state.width, this.props.mconf);
  }

  buildSubFields(field) {
    var hrows = '';
    var reslog = 'Form ' + this.props.form.id + ', width: ' + this.state.width;
    var resolution = this.getResolution(field);
    reslog += ', resolution: ' + resolution;
    resolution = MC.getAvailableResolution(resolution, field);
    reslog += ', used resolution: ' + (resolution ? resolution : 'autolayout');
    if (this.prevreslog !== reslog) {
      this.prevreslog = reslog
      MCHistory.log(MCHistory.T_INFO, reslog, field.flow.debug('DETAIL'))
    }
    if (field.fields) {
      var rows = MC.splitFieldsIntoRows(field.fields, resolution);
      hrows = [];
      for (var i = 0; i < rows.length; i++) {
        if (MC.isRowVisible(rows[i], resolution)) {
          var hrow = [];
          for (var ii = 0; ii < rows[i].length; ii++) {
            var subField = rows[i][ii];
            let offsetDiv;
            let grid = MC.getFieldGrid(subField, resolution);
            if (grid.offset > 0) {
              let cls = "mnc " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field mobile-no-100";
              offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>;
            }
            hrow.push(<Widget key={subField.id} widget={subField} resolution={resolution} offsetDiv={offsetDiv}/>);
          }
          hrows.push(<div key={i} className="mnc row">{hrow}</div>)
        }
      }
    }
    return hrows;
  }

  registerServerEvents() {
    let self = this
    if (this.props.form.param && this.props.form.param['serverEvents']) {
      for (let event of MC.asArray(this.props.form.param['serverEvents'])) {
        let key = event.type + '$' + (event.key ? event.key : '') + '$' + (event.itemId ? event.itemId : '')
        if (!this.serverEvents[key]) {
          if (typeof(SharedWorker) !== "undefined") {
            if (!this.worker) {
              this.worker = new SharedWorker(this.props.form.flow.baseUrl + 'miniapp/lib/sharedWorker.js')
              this.worker.port.onmessage = function (e) {
                self.onServerMessage(e)
              }
              window.addEventListener("beforeunload", this.unregisterServerEvents.bind(this), false)
            }
            this.worker.port.postMessage("START:" + this.props.mconf.baseUrl + 'miniapp/sse/@' + key)
            this.serverEvents[key] = true
          } else {
            let source = new EventSource(this.props.mconf.baseUrl + 'miniapp/sse/' + key)
            source.addEventListener(key, (e) => { self.onServerMessage(e) }, false)
            this.serverEvents[key] = source
          }
        }
      }
    }
  }

  onServerMessage(e) {
    if (e.data) {
      if (typeof e.data === 'string') {
        if (e.data.startsWith('{')) {
          const tokens = e.type.split('$')
          let data = {type: tokens[0], key: tokens[1], itemId: tokens[2]}
          data.data = JSON.parse(e.data).props
          if (MC.isFunction(this.props.form.flow.eventForm)) {
            this.props.form.flow.eventForm(null, tokens[0], null, data)
          }
        } if (e.data.startsWith('ERROR:') || e.data.startsWith('RECONNECT:')) {
          let urlarr = window.location.href.split("/")
          window.postMessage({name: e.data.startsWith('ERROR:') ? 'serverConnectionLostEvent' : 'serverConnectionBackEvent'}, urlarr[0] + "//" + urlarr[2]);
        }
      } else if (MC.isPlainObject(e.data)) {
        if (MC.isFunction(this.props.form.flow.eventForm)) {
          this.props.form.flow.eventForm(null, e.data.type, null, e.data)
        }
      }
    }
  }

  unregisterServerEvents() {
    for (let eventKey in this.serverEvents) {
      if (typeof(SharedWorker) !== "undefined") {
        if (this.worker) {
          this.worker.port.postMessage("STOP:" + this.props.mconf.baseUrl + 'miniapp/sse/@' + eventKey)
          this.worker.port.close()
          delete this.worker
          window.removeEventListener("beforeunload", this.unregisterServerEvents)
        }
      } else {
        this.serverEvents[eventKey].close()
      }
      delete this.serverEvents[eventKey]
    }
  }

  render() {
    let cssclass = MC.classes('mnc form', {'xs-auto': !MC.hasLayout(this.props.form, 'x-small')}, this.props.form.cssclass, this.props.form.param && this.props.form.param['@cssClass'])
    if (this.props.form.feedback && Array.isArray(this.props.form.feedback)) {
      var feedbacks = [];
      for (var i=0; i<this.props.form.feedback.length; i++) {
        var feedback = this.props.form.feedback[i];
        var color = '';
        var icon = 'info';
        switch (feedback.level) {
          case 'error': color = 'red'; icon = 'warning sign'; break;
          case 'warning': color = 'yellow'; icon = 'warning'; break;
          case 'success': color = 'green'; icon = 'checkmark'; break;
        }
        var cls = 'mnc ' + color + ' icon message';
        var iconCls = icon + ' icon';
        var help = null;
        if (!MC.isNull(feedback.help) && feedback.help !== '') {
          help = <p>{feedback.help}</p>;
        }
        feedbacks.push(
          <div className={cls} key={i}>
            <i className="close icon" onClick={this.closeFeedback.bind(this, i)}></i>
            <i className={iconCls}></i>
            <div className="content">
              <div className="header">{feedback.title}</div>
              {help}
            </div>
          </div>
        );
      }
    }
    let invalidSummary
    if (this.props.form.invalidSummary && !MC.isModelerActive(this.props.form) && MC.getFieldParamBooleanValue(this.props.form.param, '@showInvalidSummary')) {
      let errs = []
      for (let path in this.props.form.invalidSummary) {
        errs.push(<div className="item" key={path}>{this.props.form.invalidSummary[path]}</div>)
      }
      if (errs.length > 0) {
        invalidSummary = <div className="mnc negative message"><div className="mnc list">{errs}</div></div>
      }
    }
    var interactive;
    if (MC.isModelerReactAvailable(this.props.form)) {
      var modelerReact = MC.getModelerReact(this.props.form)
      var interactiveField = modelerReact.state.interactiveField;
      if (interactiveField) {
        var resolution = this.getResolution(interactiveField);
        resolution = MC.getAvailableResolution(resolution, this.props.form);
        interactive = <div className="mnc row"><Widget widget={interactiveField} resolution={resolution}/></div>
      }
    }
    let RootElem = this.props.embedded ? 'div' : 'form'
    let gridStyle = MC.isModelerActive(this.props.form) ? {margin: 0} : {}
    return (
      <RootElem key={this.props.form.flow.formExecutionId} ref={this.formRef} className={cssclass} style={MC.styleObjectFromString(MC.getFieldParamValue(this.props.form.param, '@css'))} role="form" id={this.props.form.id} onSubmit={this.handleSubmitDefault} data-widget-id={this.props.form.id} tabIndex="-1">
        {feedbacks}
        {invalidSummary}
        <div className="mnc twelve column grid" style={gridStyle}>
          {this.buildSubFields(this.props.form)}
        </div>
        {interactive}
      </RootElem>);
  }

}

export {Form};