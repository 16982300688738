import getCompositeRect from "./dom-utils/getCompositeRect.js";
import getLayoutRect from "./dom-utils/getLayoutRect.js";
import listScrollParents from "./dom-utils/listScrollParents.js";
import getOffsetParent from "./dom-utils/getOffsetParent.js";
import orderModifiers from "./utils/orderModifiers.js";
import debounce from "./utils/debounce.js";
import { isElement } from "./dom-utils/instanceOf.js";

import eventListeners from "./modifiers/eventListeners.js"
import popperOffsets from "./modifiers/popperOffsets.js"
import computeStyles from "./modifiers/computeStyles.js"
import applyStyles from "./modifiers/applyStyles.js"
import offset from "./modifiers/offset.js"
import flip from "./modifiers/flip.js"
import preventOverflow from "./modifiers/preventOverflow.js"
import arrow from "./modifiers/arrow.js"
import hide from "./modifiers/hide.js"

var DEFAULT_OPTIONS = {
  placement: 'bottom',
  strategy: 'absolute'
};

function createPopper(reference, popper, options) {

  arrow.options.element = options.arrow
  var defaultModifiers = [eventListeners, popperOffsets, computeStyles, applyStyles, offset, flip, preventOverflow, arrow, hide, options.modifier]

  var state = {
    placement: 'bottom',
    orderedModifiers: [],
    options: Object.assign({}, DEFAULT_OPTIONS, options),
    modifiersData: {},
    elements: {
      reference: reference,
      popper: popper
    },
    attributes: {},
    styles: {}
  };

  var effectCleanupFns = [];
  var isDestroyed = false;
  var instance = {
    state: state,
    setOptions: function setOptions(setOptionsAction) {
      var options = typeof setOptionsAction === 'function' ? setOptionsAction(state.options) : setOptionsAction;
      cleanupModifierEffects();
      state.options = Object.assign({}, DEFAULT_OPTIONS, state.options, options);
      state.scrollParents = {
        reference: isElement(reference) ? listScrollParents(reference) : reference.contextElement ? listScrollParents(reference.contextElement) : [],
        popper: listScrollParents(popper)
      }; // Orders the modifiers based on their dependencies and `phase`
      // properties

      state.orderedModifiers = orderModifiers(defaultModifiers)

      runModifierEffects();
      return instance.update();
    },
    // Sync update – it will always be executed, even if not necessary. This
    // is useful for low frequency updates where sync behavior simplifies the
    // logic.
    // For high frequency updates (e.g. `resize` and `scroll` events), always
    // prefer the async Popper#update method
    forceUpdate: function forceUpdate() {
      if (isDestroyed) {
        return;
      }

      var _state$elements = state.elements,
          reference = _state$elements.reference,
          popper = _state$elements.popper; // Don't proceed if `reference` or `popper` are not valid elements
      // anymore


      state.rects = {
        reference: getCompositeRect(reference, getOffsetParent(popper)),
        popper: getLayoutRect(popper)
      }; // Modifiers have the ability to reset the current update cycle. The
      // most common use case for this is the `flip` modifier changing the
      // placement, which then needs to re-run all the modifiers, because the
      // logic was previously ran for the previous placement and is therefore
      // stale/incorrect

      state.reset = false;
      state.placement = state.options.placement; // On each update cycle, the `modifiersData` property for each modifier
      // is filled with the initial data specified by the modifier. This means
      // it doesn't persist and is fresh on each update.
      // To ensure persistent data, use `${name}#persistent`

      state.orderedModifiers.forEach(function (modifier) {
        return state.modifiersData[modifier.name] = Object.assign({}, modifier.data);
      });

      for (var index = 0; index < state.orderedModifiers.length; index++) {
        if (state.reset === true) {
          state.reset = false;
          index = -1;
          continue;
        }

        var _state$orderedModifie = state.orderedModifiers[index],
            fn = _state$orderedModifie.fn,
            _state$orderedModifie2 = _state$orderedModifie.options,
            _options = _state$orderedModifie2 === void 0 ? {} : _state$orderedModifie2,
            name = _state$orderedModifie.name;

        if (typeof fn === 'function') {
          state = fn({
            state: state,
            options: _options,
            name: name,
            instance: instance
          }) || state;
        }
      }
    },
    // Async and optimistically optimized update – it will not be executed if
    // not necessary (debounced to run at most once-per-tick)
    update: debounce(function () {
      return new Promise(function (resolve) {
        instance.forceUpdate();
        resolve(state);
      });
    }),
    destroy: function destroy() {
      cleanupModifierEffects();
      isDestroyed = true;
    }
  };

  instance.setOptions(options).then(function (state) {
    if (!isDestroyed && options.onFirstUpdate) {
      options.onFirstUpdate(state);
    }
  }); // Modifiers have the ability to execute arbitrary code before the first
  // update cycle runs. They will be executed in the same order as the update
  // cycle. This is useful when a modifier adds some persistent data that
  // other modifiers need to use, but the modifier is run after the dependent
  // one.

  function runModifierEffects() {
    state.orderedModifiers.forEach(function (mod) {
      if (typeof mod.effect === 'function') {
        var cleanupFn = mod.effect({
          state: state,
          name: mod.name,
          instance: instance,
          options:  mod.options || {}
        });

        var noopFn = function noopFn() {};

        effectCleanupFns.push(cleanupFn || noopFn);
      }
    });
  }

  function cleanupModifierEffects() {
    effectCleanupFns.forEach(function (fn) {
      return fn();
    });
    effectCleanupFns = [];
  }

  return instance;
}

export {createPopper}