import React from "react"
import { DateTime } from "luxon"
import { MC } from "../MC"

class DateTimePickerDays extends React.Component {

  render() {
    let footer = this.renderFooter()
    let date = this.props.adjustDateMinMax(this.props.viewDate)
    this.viewDate = date
    let locale = date.locale
    let isAllowedPrev = this.props.modelerActive ? true : MC.isValidDay(date.minus({days: 1}), this.props.min, this.props.max)
    let isAllowedNext = this.props.modelerActive ? true : MC.isValidDay(date.plus({months: 1}), this.props.min, this.props.max)
    let tableChildren = ([
        <thead key="th">
          <tr key="h">
            <th key="p" className={MC.classes("rdtPrev", !isAllowedPrev && "rdtDisabled")} onClick={isAllowedPrev ? this.props.subtractTime(1, 'months') : null}><span>‹</span></th>
            <th key="s" className="rdtSwitch" onClick={this.props.showView('months')} colSpan={5} data-value={date.month}>{date.toFormat('LLLL') + ' ' + date.year}</th>
            <th key="n" className={MC.classes("rdtNext", !isAllowedNext && "rdtDisabled")} onClick={isAllowedNext ? this.props.addTime(1, 'months') : null}><span>›</span></th>
          </tr>
          <tr key="d">{this.getDaysOfWeek(locale).map(function (day, index) { return <th key={day + index} className="dow">{day}</th>})}</tr>
        </thead>,  
        <tbody key="tb">{this.renderDays(locale, date)}</tbody>
    ])
    if (footer) {
      tableChildren.push(footer)
    }
    return <div className="rdtDays"><table>{tableChildren}</table></div>
  }

  getDaysOfWeek(locale) {
    let day = DateTime.local().setLocale(locale).startOf('week')
    if (locale == 'en') {
      day = day.minus({day: 1})
    }
    let days = []
    for (let i = 0; i<7; i++) {
      days[i] = day.toFormat('ccc')
      day = day.plus({ days: 1 })
    }
    return days
  }

  renderDays(locale, date) {
    let self = this
    let selected = this.props.selectedDate && this.props.selectedDate
    let prevMonth = date.minus({months: 1})
    let currentYear = date.year
    let currentMonth = date.month
    let weeks = []
    let days = []
    // Go to the last week of the previous month
    prevMonth = prevMonth.set({day: prevMonth.daysInMonth}).startOf('week')
    if (locale == 'en') {
      prevMonth = prevMonth.minus({day: 1})
    }
    let lastDay = prevMonth.plus({days: 42})
    while (prevMonth < lastDay) {
      let classes = 'rdtDay'
      let currentDate = prevMonth
      if ((prevMonth.year === currentYear && prevMonth.month < currentMonth) || (prevMonth.year < currentYear))
        classes += ' rdtOld'
      else if ((prevMonth.year === currentYear && prevMonth.month > currentMonth) || (prevMonth.year > currentYear))
        classes += ' rdtNew'
      if (selected && prevMonth.hasSame(selected, 'day'))
        classes += ' rdtActive'
      if (prevMonth.hasSame(DateTime.local(), 'day'))
        classes += ' rdtToday'
      let isDisabled = !self.props.isValidDay(currentDate)
      if (isDisabled)
        classes += ' rdtDisabled'
      let dayProps = {
        key: prevMonth.toFormat('M_d'),
        'data-value': prevMonth.day,
        className: classes
      }
      if (!isDisabled) {
        dayProps.onClick = this.updateSelectedDate
      }
      days.push(this.renderDay(dayProps, currentDate))
      if (days.length === 7) {
        weeks.push(<tr key={prevMonth.toFormat('M_d')}>{days}</tr>)
        days = []
      }
      prevMonth = prevMonth.plus({days: 1})
    }
    return weeks
  }

  updateSelectedDate = (event) => {
    this.props.updateSelectedDate(event, true, this.viewDate)
  }

  renderDay(props, currentDate) {
    return <td {...props}>{currentDate.day}</td>
  }

  renderFooter() {
    if (!this.props.timeFormat) {
      return ''
    }
    var date = this.props.selectedDate || this.props.viewDate
    return (
      <tfoot key="tf">
        <tr>
          <td onClick={this.props.showView('time')} colSpan={7} className="rdtTimeToggle">{date.toFormat(this.props.timeFormat)}</td>
        </tr>
      </tfoot>
    )
  }

}

export default DateTimePickerDays