import React from "react"

import {MC} from './MC.js'

class Button extends React.Component {

  state = {rippleStyle: {}}

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.timerAfter)
  }

  handleClick = (e) => {
    let field = this.props.button
    if (MC.isModelerActive(field)) {
      return
    }
    if (field.flow.getCfgParameter("mini:buttonRipple") === 'true') {
      this.ripple(e)
    }
    e.preventDefault()
    MC.handleEvent(field, 'click', null, e)
    if ('url' == MC.getFieldParamValue(field.param, '@behavior')) {
      let url =  MC.rebaseLink(field.flow, MC.getFieldParamValue(field.param, '@url'))
      if (!MC.isNull(url)) {
        // has to be done via javascript, not a "<a target=" for have button html always as button not anchor
        let target = MC.getFieldParamValue(field.param, '@target')
        if (target == 'blank') {
          window.open(url)
        } else if (target == 'top') {
          window.top.location.href = url
        } else if (target == 'parent') {
          window.parent.location.href = url
        } else {
          field.flow.reactFlow().routeTo(e, url)
        }
        return
      }
    } else {
      this.props.button.flow.handleSubmit(this.props.button)
    }
  }

  handleDblClick = (e) => {
    MC.handleEvent(this.props.button, 'doubleclick', null, e)
  } 

  ripple(ev) {
    let elem = this.props.widgetRef.current
    let pos = MC.getElemCoords(elem);
    let rect = elem.getBoundingClientRect();
    let left = ev.pageX - pos.left;
    let top = ev.pageY - pos.top;
    let size = Math.max(rect.width, rect.height);
    this.setState({ rippleStyle: {top: top, left: left, opacity: 1, transform: 'translate(-50%, -50%)', transition: 'initial', width: '35px', height: '35px'} });
    this.timer = setTimeout(() => {
      this.setState({ rippleStyle:  {top: top, left: left, opacity: 0, transform: 'scale(' + size / 9 + ')', transition: 'all 600ms', width: '35px', height: '35px'}});
      this.timerAfter = setTimeout(() => {
        this.setState({ rippleStyle:  {width: '0px', height: '0px'}})
      }, 600);
    });
  }

  render() {
    let field = this.props.button
    let label = this.props.value
    let cls = MC.classes('mnc button ripper-wrapper', {'icon': MC.isNull(label) || label === ""}, MC.getFieldParamValue(field.param, '@cssClass'))
    if (!MC.getFieldParamValue(field.param, '@escapeHtml')) {
      label = <div dangerouslySetInnerHTML={{__html: MC.customHtml(label)}}/>
    }
    return (
      <button ref={this.props.widgetRef} type="button" className={cls} style={MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))} disabled={this.props.disabled} onClick={this.handleClick} onDoubleClick={this.handleDblClick} data-widget-i-name={field.id}>
        {MC.iconize(field, label)}
        <s style={this.state.rippleStyle} className="ripper"/>
      </button>
    )
  }
}

export {Button}