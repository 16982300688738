import React from "react"

import {MC} from './MC.js'

class WhisperBox extends React.Component {

  state = {matchedData: [], showDropdown: false, data: [], activeIndex: null, param: {}}
  rootRef = React.createRef()

  componentDidMount() {
    this.updateData(this.props)
    document.addEventListener('MNC.CLICK', this.handleOutsideClick)
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
    document.removeEventListener('click', this.handleOutsideClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!MC.objectEqual(prevState.param, this.props.data.param, true)) {
      this.updateData(this.props)
    }
    if (!prevState.showDropdown && this.state.showDropdown) {
      this.setOpenDirection()
      window.addEventListener("scroll", this.setOpenDirection, true)
    } else if (prevState.showDropdown && !this.state.showDropdown) {
      window.removeEventListener("scroll", this.setOpenDirection, true)
      this.removeDynamicStyle()
    }
    if (!MC.objectEqual(prevState.matchedData, this.state.matchedData, true)) {
      this.setOpenDirection()
    }
  }

  setOpenDirection = (e) => {
    if (!this.rootRef.current) return
    const menu = this.rootRef.current.querySelector('.fixed-to-recalc')
    if (e instanceof Event && e.target.classList && e.target.classList.contains('dropdown-ul') || !menu) {
      return
    }
    this.removeDynamicStyle()
    const parent = menu.parentElement
    const rect = parent.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    menu.style.minWidth = `${rect.width}px`
    menu.style.top = `${rect.bottom}px`
    menu.style.removeProperty('bottom')
    let spaceBelow = viewportHeight - rect.bottom
    let spaceAbove = rect.top
    menu.style.left = `${rect.left}px`
    menu.style.removeProperty('right')
    parent.classList.remove('upward')
    const menuHeight = menu.offsetHeight
    if (menuHeight > spaceBelow) {
      if (spaceAbove > spaceBelow) {
        menu.style.bottom = `${viewportHeight - rect.top}px`
        menu.style.removeProperty('top')
        parent.classList.add('upward')
        if (menuHeight > spaceAbove) {
          menu.style.height = `${spaceAbove - 3}px`
        }
      } else {
        menu.style.height = `${spaceBelow - 3}px`
      }
    }
  }

  removeDynamicStyle = () => {
    const menu = this.rootRef.current.querySelector('.fixed-to-recalc')
    menu.style.removeProperty('min-width')
    menu.style.removeProperty('top')
    menu.style.removeProperty('bottom')
    menu.style.removeProperty('left')
    menu.style.removeProperty('right')
    menu.style.removeProperty('height')
    menu.parentElement.classList.remove('upward')
  }  

  updateData(props) {
    var field = props.data;
    var value = MC.getFieldParamValue(props.data.param, 'value')
    var titleValue = MC.getFieldParamValue(props.data.param, 'text')
    let newState = {...this.state, param: MC.extend({}, props.data.param)}
    var data = []
    if (field.param['items']) {
      for (let item of field.param['items']) {
        if (!MC.isNull(item['@key'])) {
          data.push({key: item['@key'].toString(), title: MC.isNull(item['@title']) ? item['@key'].toString() : item['@title'].toString(), titleHtml: item['@titleHtml']})
          if (!MC.isNull(value) && MC.isNull(titleValue)) {
            if (value == item['@key'].toString()) {
              this.handleSetValue(value, item['@title'].toString())
            }
          } else if (!MC.isNull(titleValue) && MC.isNull(value)) {
            if (titleValue == item['@title'].toString()) {
              this.handleSetValue(item['@key'].toString(), titleValue)
            }
          }
        }
      }
    }
    if (MC.getFieldParamBooleanValue(props.data.param, '@forceSearch')) {
      MC.putFieldParamValue(props.data.param, '@forceSearch', false)
      newState.matchedData = data
      newState.showDropdown = data.length > 0
    }
    newState = {...newState, data: data, activeIndex: null}
    if (!MC.objectEqual(this.state, newState, true)) {
      this.setState(newState)
    }
  }

  handleChange = (e) => {
    let value = e.target.value
    this.props.widget.handleTextChange(value, value)
    this.triggerChange(value)
  }

  triggerChange(value) {
    if (value.trim()) {
      let matchedData = this.searchQuery(value.trim(), this.state.data)
      this.setState({matchedData: matchedData, showDropdown: matchedData.length > 0, activeIndex: null})
    } else {
      this.setState({showDropdown: false})
    }
  }  

  searchQuery(value, array) {
    var whisperType =  MC.getFieldParamValue(this.props.data.param, '@whisperType')
    var searchTitle = true;
    if (whisperType == 'value') {
      searchTitle = false;
    }
    var searchKey = false;
    if (whisperType == 'value' || whisperType == 'both') {
      searchKey = true;
    }
    var result = [];
    value = value.toLowerCase()
    for (var i=0; i<array.length; i++) {
      if (searchKey && array[i]['key'].toLowerCase().indexOf(value) !== -1 || searchTitle && array[i]['title'].toLowerCase().indexOf(value) !== -1) {
        result.push(array[i]);
      }
    }
    return result;
  }

  handleSetValue(value, titleValue) {
    this.setState({showDropdown: false}, function() {
      if (this.props.textMode) {
        return
      }
      this.props.widget.handleTextChange(value, titleValue, {notypingbreak: true})
      this.props.widgetRef.current.focus()
    })
  }

  handleNoValue() {
    this.setState({showDropdown: false}, function() {
      this.props.widget.handleTextChange(null, null, {notypingbreak: true})
    })
  }

  activateItem(i) {
    this.setState({activeIndex: i});
  }

  handleClickItem = (item) => {
    if (this.props.readOnly) {
      return
    }
    this.handleSetValue(item['key'], item['title'])
  }  

  handleKeyDown = (e) => {
    if (!this.state.matchedData || !Array.isArray(this.state.matchedData) || this.props.readOnly) {
      return;
    }
    if (e.key == 'Enter') {
      if (Number.isInteger(this.state.activeIndex) && (this.state.matchedData && Array.isArray(this.state.matchedData))) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        this.handleSetValue(this.state.matchedData[this.state.activeIndex]['key'], this.state.matchedData[this.state.activeIndex]['title'])
      }
    } else if (e.key == 'Escape') {
      if (this.state.showDropdown) {
        e.stopPropagation()
        e.preventDefault()
      }
      this.setState({activeIndex: null, showDropdown: false});
    } else if (e.key == 'ArrowUp') {
      if (this.state.showDropdown && this.state.matchedData.length > 0) {
        if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex > 0) {
          this.setState({activeIndex: this.state.activeIndex - 1});
        } else {
          this.setState({activeIndex: this.state.matchedData.length -1});
        }
      }
    } else if (e.key == 'ArrowDown') {
      if (this.state.showDropdown && this.state.matchedData.length > 0) {
        if (Number.isInteger(this.state.activeIndex) && this.state.activeIndex + 1 < this.state.matchedData.length) {
          this.setState({activeIndex: this.state.activeIndex + 1});
        } else {
          this.setState({activeIndex: 0});
        }
      }  
    } else if (e.key == 'Tab') {
      if (this.state.showDropdown) {
        if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
          this.handleNoValue()
        } else {
          this.setState({activeIndex: null, showDropdown: false})
        }
      }
    }
  }

  handleOutsideClick = (e) => {
    if (this.props.textMode || this.rootRef.current.contains(e.detail.target) || this.props.readOnly) {
      return
    }
    if (this.state.showDropdown) {
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@forceValue')) {
        this.handleNoValue()
      } else {
        this.setState({activeIndex: null, showDropdown: false})
      }
    }
  }

  render() {
    let inputCls = MC.classes("input", this.state.showDropdown && "input-show-dropdown", this.props.cssClass)
    let dropDown = null
    if (this.state.matchedData && Array.isArray(this.state.matchedData) && this.state.matchedData.length > 0) {
      var mdata = this.state.matchedData;
      var items = [];
      for (var i=0; i<mdata.length; i++) {
        let cls = 'dropdown-ul-li' + (this.state.activeIndex === i ? ' active' : '');
        if (mdata[i]['titleHtml']) {
          items.push(<li key={i} className={cls} dangerouslySetInnerHTML={{__html: mdata[i]['titleHtml']}} onClick={this.handleClickItem.bind(this, mdata[i])} onMouseEnter={this.activateItem.bind(this, i, true)}></li>)
        } else {
          items.push(<li key={i} className={cls} onClick={this.handleClickItem.bind(this, mdata[i])} onMouseEnter={this.activateItem.bind(this, i, true)}>{mdata[i]['title']}</li>)
        }
      }
      const maxHeight = MC.getFieldParamValue(this.props.data?.param, '@maxDropDownHeight')
      dropDown = <div className="dropdown"><ul className="dropdown-ul" style={{maxHeight: maxHeight}}>{items}</ul></div>
    }
    let titleValue = MC.getFieldParamValue(this.props.data.param, 'text') || ""
    if (this.props.textMode) {
      return titleValue || '\u00A0'
    }
    let input =  <input type="text" className={inputCls} style={this.props.css} placeholder={this.props.placeholder} onChange={this.handleChange} value={titleValue} readOnly={this.props.readOnly} disabled={this.props.disabled} 
                   data-widget-i-name={this.props.data.id} ref={this.props.widgetRef} size={MC.getFieldParamValue(this.props.data.param, '@size')}/>
    let icon = MC.getFieldParamValue(this.props.data.param, '@icon')
    if (icon) {
      let iconPlacement = MC.getFieldParamValue(this.props.data.param, '@iconPlacement')
      input = <div className={MC.classes("mnc", {"left": iconPlacement !== 'right'}, "icon input")} key="inwrap">{input}<i key="icon" className={MC.buildIconClass(icon)}></i></div>
    }
    let cls = MC.classes('whisperbox')
    let menuCls = MC.classes('search-dropdown fixed-to-recalc', {'visible': this.state.showDropdown})
    return (
      <div className={cls} onKeyDown={this.handleKeyDown} ref={this.rootRef}>
        <div className="search-input">{input}</div>
        <div className={menuCls}>{dropDown}</div>
      </div>
    );
  }

}

export {WhisperBox}
