import getBoundingClientRect from "./getBoundingClientRect.js";
import getNodeScroll from "./getNodeScroll.js";
import getNodeName from "./getNodeName.js";
import { isHTMLElement } from "./instanceOf.js";
import getWindowScrollBarX from "./getWindowScrollBarX.js";
import getDocumentElement from "./getDocumentElement.js";
import isScrollParent from "./isScrollParent.js";

function isElementScaled(element) {
  var rect = element.getBoundingClientRect();
  var scaleX = Math.round(rect.width) / element.offsetWidth || 1;
  var scaleY = Math.round(rect.height) / element.offsetHeight || 1;
  return scaleX !== 1 || scaleY !== 1;
} // Returns the composite rect of an element relative to its offsetParent.
// Composite means it takes into account transforms as well as layout.


export default function getCompositeRect(elementOrVirtualElement, offsetParent) {
  var isOffsetParentAnElement = isHTMLElement(offsetParent);
  var offsetParentIsScaled = isHTMLElement(offsetParent) && isElementScaled(offsetParent);
  var documentElement = getDocumentElement(offsetParent);
  var rect = getBoundingClientRect(elementOrVirtualElement, offsetParentIsScaled);
  var scroll = {scrollLeft: 0,scrollTop: 0};
  var offsets = {x: 0,y: 0};
  if (isOffsetParentAnElement || !isOffsetParentAnElement) {
    if (getNodeName(offsetParent) !== 'body' || 
    isScrollParent(documentElement)) {
      scroll = getNodeScroll(offsetParent);
    }
    if (isHTMLElement(offsetParent)) {
      offsets = getBoundingClientRect(offsetParent, true);
      offsets.x += offsetParent.clientLeft;
      offsets.y += offsetParent.clientTop;
    } else if (documentElement) {
      offsets.x = getWindowScrollBarX(documentElement);
    }
  }
  return {
    x: rect.left + scroll.scrollLeft - offsets.x,
    y: rect.top + scroll.scrollTop - offsets.y,
    width: rect.width,
    height: rect.height
  };
}