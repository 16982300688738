import React from "react"

import {MC} from './MC.js'

class Media extends React.Component {

  render() {
    const field = this.props.data
    let cssclass = MC.getFieldParamValue(field.param, '@cssClass')
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    const contentType = MC.getFieldParamValue(field.param, '@contentType')
    const showAsLink = MC.getFieldParamValue(field.param, '@showAsLink')
    const value = MC.getFieldParamValue(field.param, 'value')
    if (showAsLink == true || field.widget == 'defaultviewer') {
      let fileName = MC.getFieldParamValue(field.param, '@fileName')
      if (MC.isNull(fileName)) {
        fileName = "..."
      }
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (MC.isNull(uri)) {
        uri = 'data:' + contentType + ';base64,' + value
      } else {
        uri = MC.rebaseUrl(MC.findRoot(field).componentName, uri, field.flow.baseUrl)
      }
      return <a href={uri} download={fileName} target="_blank" data-widget-i-name={field.id} className={cssclass} style={css}><i className="file icon"/>{fileName}</a>
    } else if (field.widget == 'imageviewer') {
      cssclass = MC.classes('mnc image', cssclass, {'centered': this.props.halign == 'center', 'right aligned': this.props.halign == 'right'})
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNullOrEmpty(uri)) {
        uri = MC.rebaseUrl(MC.findRoot(field).componentName, uri, field.flow.baseUrl)
      } else if (!MC.isNullOrEmpty(value)) {
        uri = 'data:' + contentType + ';base64,' + value
      }
      if (MC.isNullOrEmpty(uri)) {
        return <span className={cssclass} style={css} data-widget-i-name={field.id}/>
      } else {
        return <img className={cssclass} style={css} src={uri} data-widget-i-name={field.id}/>
      }
    } else if (field.widget == 'pdfviewer') {
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(MC.findRoot(field).componentName, uri, field.flow.baseUrl)
      } else if (!MC.isNull(value)) {
        uri = 'data:application/pdf;base64,' + value
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} style={css} data-widget-i-name={field.id}/>
      } else {
        return <iframe className={cssclass} src={uri} width="100%" style={{...css, height: MC.getFieldParamValue(field.param, '@height')}} data-widget-i-name={field.id}/>
      }
    } else if (field.widget == 'videoviewer') {
      let uri = MC.getFieldParamValue(field.param, '@url')
      if (!MC.isNull(uri)) {
        uri = MC.rebaseUrl(MC.findRoot(field).componentName, uri, field.flow.baseUrl)
      } else if (!MC.isNull(value)) {
        uri = 'data:' + contentType + ';base64,' + value
      }
      if (MC.isNull(uri)) {
        return <span className={cssclass} style={css} data-widget-i-name={field.id}/>
      } else {
        let controls = MC.getFieldParamBooleanValue(field.param, '@controls')
        let autoplay = MC.getFieldParamBooleanValue(field.param, '@autoplay')
        let muted = MC.getFieldParamBooleanValue(field.param, '@muted')
        muted = !autoplay ? muted : true
        let loop = MC.getFieldParamBooleanValue(field.param, '@loop')
        return <video className={cssclass} style={css} data-widget-i-name={field.id} autoPlay={autoplay} muted={muted} controls={controls} loop={loop}><source src={uri} type={contentType}/>Your browser does not support the video tag.</video>
      }
    }
  }

}

export {Media}