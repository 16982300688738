import React from "react"

import {MC} from './MC.js'
import {Value} from "./Value.js"
import tt from "./tooltip/tooltip.js"

class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.onclick = this.onclick.bind(this)
    this.menuRef = React.createRef()
    this.tolltipInstances = []
  }

  componentDidMount() {
    if (!MC.isModelerActive(this.props.data)) {
      if (this.menuRef.current) {
        let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
        this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
      }
    }
  }

  componentDidUpdate() {
    if (this.toggled && this.menuRef.current) {
      this.toggled = false
      let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
      for (let i of this.tolltipInstances) {
        i.destroy()
      }
      this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
    }  
  }

  recalculateActiveKey(activeKey, items) {
    if (Array.isArray(items)) {
      for (let item of items) {
        let activeItem = this.recalculateActiveKey(activeKey, item.items)
        if (item.key && item.key == activeKey || activeItem) {
          item.active = true
          item.opened = true
          return true
        }
      }
    }
  }

  getItemByIndex(index) {
    index = index.split("-")
    let item = MC.getFieldParamValue(this.props.data.param, 'items')[index.shift()]
    while (index.length > 0) {
      item = item.items[index.shift()]
    }
    return item
  }

  closeDropDown(items) {
    if (Array.isArray(items) && items.length > 0) {
      for (let item of items) {
        if (item.opened) {
          item.opened = false
          this.toggled = true
        }
        this.closeDropDown(item.items) 
      }  
    }
    this.forceUpdate()
  }

  onclick(e) {
    let item = this.getItemByIndex(e.currentTarget.dataset.i)
    if (MC.eventHasKey(e) && !MC.isNull(item.url)) {
      return
    }
    let field = this.props.data
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url' || item.enabled == false) {
      e.preventDefault()
    }
    e.stopPropagation()
    if (item.enabled == false) {
      return
    }
    MC.putFieldParamValue(this.props.data.param, '@focusedKey', item.key)
    if (Array.isArray(item['items']) && item['items'].length > 0 && item['items'][0] !== '' && MC.isNull(item.url)) {
      item.opened = !item.opened
      this.toggled = true
      this.forceUpdate()
      MC.handleEvent(field, 'change')
    } else {
      if (behavior == 'url') {
        if (!MC.isNull(item.url)) {
          let target = MC.getFieldParamValue(field.param, '@target')
          if (['blank', 'parent', 'top'].indexOf(target) > -1) {
            return
          }
          this.props.data.flow.reactFlow().routeTo(e, item.url)
          return
        }
      } else {
        MC.putFieldParamValue(this.props.data.param, '@activeKey', item.key)
        MC.handleEvent(field, 'change')
        this.forceUpdate()
        field.flow.handleSubmit(field)
      }
    }
    MC.handleEvent(field, 'click', null, e)
  }

  onOpenIconClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let item = this.getItemByIndex(e.currentTarget.closest('.mnc.item').dataset.i)
    if (item.enabled == false) {
      return
    }
    item.opened = !item.opened
    this.toggled = true
    this.forceUpdate()
    MC.putFieldParamValue(this.props.data.param, '@focusedKey', item.key)
    MC.handleEvent(this.props.data, 'change')
  }

  buildSubmenu(item, rooti) {
    let submenu = ""
    let params = this.props.data.param
    let activeKey = MC.getFieldParamValue(params, '@activeKey')
    let behavior = MC.getFieldParamValue(params, '@behavior')
    if (MC.isNull(rooti) && !MC.isNullOrEmpty(activeKey)) {
      if (this.activeKey !== activeKey || !this.props.data.activeKeyWasRecalculated) {
        this.recalculateActiveKey(activeKey, item['items'])
        this.props.data.activeKeyWasRecalculated = true
        this.activeKey = activeKey
      }
    }
    let target = MC.getFieldParamValue(this.props.data.param, '@target')
    target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
    if (Array.isArray(item['items']) && item['items'].length > 0) {
      if (item.opened || MC.isNull(rooti)) {
        let iconPlacement = MC.getFieldParamValue(params, '@iconPlacement') || 'left'
        let items = []
        for (let i=0; i<item['items'].length; i++) {
          let strI = !MC.isNull(rooti) ? rooti + '-' + i : i.toString()
          let propItem = item['items'][i]
          let siconLeft = null
          let siconRight = null
          if (typeof propItem.icon == 'string') {
            if (iconPlacement == 'right') {
              siconRight = <span className="icon-span-right"><i className={MC.buildIconClass(propItem.icon)}/></span>
            } else {
              siconLeft = <span className="icon-span-left"><i className={MC.buildIconClass(propItem.icon)}/></span>
            }
          }
          let submenu = this.buildSubmenu(propItem, strI)
          let active = propItem.key && activeKey && propItem.key == activeKey
          let cls = MC.classes('mnc', {'active': active, 'opened': submenu, 'disabled': propItem.enabled == false, 'item-wrapper': !submenu}, propItem.cssclass, 'item curpointer')         
          let tooltip = propItem.tooltip ? propItem.tooltip : null
          let href = null
          if (!MC.isNull(propItem.url) && propItem.url !== '' || propItem.url === '' && behavior == 'url') {
            href = MC.rebaseLink(this.props.data.flow, propItem.url)
          }
          let title = <React.Fragment>{siconLeft}{Value.castToScalar(Value.fromJson(propItem.title), 'string').value || '\u00A0'}{siconRight}</React.Fragment>
          if (href) {
            title = <a href={href} className={MC.classes({'active': active})} target={target}>{title}</a>
          }
          let openIco = null
          if (propItem.openable !== false && Array.isArray(propItem['items']) && propItem['items'].length > 0 && propItem['items'][0] !== '') {
            openIco = <i className="angle right icon"/>
            if (propItem.opened) {
              openIco = <i className="angle down icon"/>
            }
            openIco = <span className="open-ico" onClick={this.onOpenIconClick}>{openIco}</span>
          }
          let itemWrap = <React.Fragment>{openIco}{title}</React.Fragment>
          if (submenu) {
            itemWrap = <div className={MC.classes({'active': active}, 'item-wrapper')} data-tt-content={tooltip}>{itemWrap}</div>
            tooltip = null
          }
          items.push(<div className={cls} style={MC.styleObjectFromString(propItem.css)} onClick={this.onclick} data-i={strI} key={'item' + strI} data-tt-content={tooltip}>{itemWrap}{submenu}</div>)
        }
        let mcls = MC.classes('menu transition visible')
        let ref = null
        if (MC.isNull(rooti)) {
          ref = this.menuRef
          mcls = MC.classes('mnc menutree', MC.getFieldParamBooleanValue(params, '@fitWidth') ? 'fluid' : 'compact', MC.getFieldParamValue(params, '@cssClass'))
        }
        submenu = <div className={mcls} style={MC.styleObjectFromString(MC.getFieldParamValue(params, '@css'))} ref={ref}>{items}</div>
      }
    }
    return submenu
  }

  render() {
    return this.buildSubmenu(this.props.data.param, null)
  }
}

export default Menu
