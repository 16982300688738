import {isHTMLElement} from "./instanceOf.js";
export default function getBoundingClientRect(element, includeScale = false) {
  var clientRect = element.getBoundingClientRect();
  var scaleX = 1;
  var scaleY = 1;
  if (includeScale && isHTMLElement(element)) {
    scaleX = element.offsetWidth > 0 ? Math.round(clientRect.width) / element.offsetWidth || 1 : 1;
    scaleY = element.offsetHeight > 0 ? Math.round(clientRect.height) / element.offsetHeight || 1 : 1;
  }
  var x = clientRect.left / scaleX;
  var y = clientRect.top / scaleY;
  var width = clientRect.width / scaleX;
  var height = clientRect.height / scaleY;
  return {
    width: width,
    height: height,
    top: y,
    right: x + width,
    bottom: y + height,
    left: x,
    x: x,
    y: y
  }
}