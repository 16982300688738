import {DateTime} from "luxon"

import {MC} from './MC.js'
import {Value} from './Value.js'

const MCHistory = {

  historyArr: [],
  logArr: [],
  flowInstance: {},
  T_ERROR: 'ERROR',
  T_EXCEPTION: 'EXCEPTION',
  T_WARNING: 'WARNING',
  T_INFO: 'INFO',

  log: function(type, text, debug) {
    if (MC.isNull(type) || MC.isNull(text)) {
      console.error("Bad log function calling!")
    }
    if (debug) {
      this.logArr.push({type: type, mess: text, time: new Date()})
      if (type == this.T_ERROR) {
        console.error(this.T_ERROR + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else if (type == this.T_EXCEPTION) {
        console.error(this.T_EXCEPTION + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else if (type == this.T_WARNING) {
        console.warn(this.T_WARNING + ' - ' + (new Date()).toISOString() + ': ' + text)
      } else {
        console.log(this.T_INFO + ' - ' + (new Date()).toISOString() + ': ' + text)
      }
    }
  },

  history: function(flowObject, action, text, logObject, opts) {
    if (MC.isNull(flowObject)) {
      console.error("Bad log function calling!")
    }
    if (flowObject.debug('MINIMAL')) {
      let atleastBasic = flowObject.debug('BASIC')
      if (!atleastBasic && (!logObject || !logObject.executionId)) { // for logging tree in minmal for debugger, if not has children do not log
        return
      }  
      if (atleastBasic) {
        this.putFlowInstanceInfo(flowObject)
      }
      let flow = flowObject.flow
      let oldRec = null
      let record = {}
      if (action && (action.kind == 'call' || action.kind == 'framework') && opts && logObject.executionId) {
        oldRec = this.historyArr.find(oe => oe.actionid == action.id && oe.executionActionId == logObject.executionId)
        if (oldRec) {
          record = oldRec
          delete record.text
          delete record.archived
        }
      }
      if (!atleastBasic) { // for logging tree in minmal for debugger
        record.archived = true
      }
      if (atleastBasic) {
        record.path = MCHistory.getFlowPath(flowObject)
        if (opts) {
          if (opts.start) {
            record.start = MC.luxonToDateTimeString({v: DateTime.fromMillis(opts.start)})
          }
          if (opts.end) {
            record.end = MC.luxonToDateTimeString({v: DateTime.fromMillis(opts.end)})
          }
          if (opts.duration !== undefined) {
            record.duration = Math.round(opts.duration * 1000)
          }
          if (opts.logic) {
            record.logic = {name: opts.logic.name, id: opts.logic.rbsid}
          }
        }
        if (flow) {
          record.flow = flow.id
          record.flowKind = flow.kind
        }
      }
      if (!MC.isNull(action)) {
        record.action = action.code
        record.kind = action.kind
        record.actionid = action.id
      } else if (flow) {
        record.kind = flow.kind
      }
      if (atleastBasic && logObject && logObject.target) {
        if (record.kind == 'call' || record.kind == 'dialog' || record.kind == 'framework') {
          record.target = {id: logObject.target.rbsid, name: logObject.target.id, component: logObject.target.model, type: 'operation', kind: logObject.target.kind}
        } else if (record.kind == 'form') {
          record.target = {id: logObject.target.formId, name: logObject.target.id, component: logObject.target.model, type: 'form', kind: 'form'}
        } 
        delete logObject.target
      }
      record.text = text
      record.instanceId = flowObject.instanceId
      if (logObject && logObject.executionId) {
        record.executionActionId = logObject.executionId
        delete logObject.executionId
      }
      if (logObject && logObject.isException) {
        record.isException = true
        delete logObject.isException
        if (logObject.exceptionHandled) {
          record.exceptionHandled = true
          delete logObject.exceptionHandled
        }
      }
      record.logObject = record.logObject || {} 
      if (!MC.isNull(logObject) && MC.isPlainObject(logObject) && flowObject.debug('DETAIL')) {
        for (let root in logObject) {
          if (!MC.isNull(logObject[root])) { // filter null objects
            if (['Input', 'Environment', 'Output'].indexOf(root) > -1) {
              if (!Value.isNullOrEmpty(logObject[root])) {
                record.logObject[root] = Value.toLiteral(logObject[root])
              }
            } else {
              record.logObject[root] = logObject[root]
            }
          }
        }
      }
      if (!oldRec) {
        this.historyArr.push(record)
      }
      if (atleastBasic) {
        console.log((record.path ? record.path + ' / ' : '') + record.flow + (record.action ? ' / ' + record.action : '')  + ' (' + record.kind + ')' + (record.duration !== undefined ? ' -  ' +  (record.duration/1000).toFixed(1) + 'ms (' + record.end + ')' : '') + (record.text ? ' - ' + record.text : ''))
        if (!MC.isNull(record.logObject) && flowObject.debug('DETAIL')) {
          for (let root in record.logObject) {
            console.log('       ' + root + ':', record.logObject[root])
          }
        }
      }
    }
  },

  putFlowInstanceInfo(flowObject) {
    if (!this.flowInstance[flowObject.instanceId]) {
      let operation = flowObject.flow ? {name: flowObject.flow.id, component: flowObject.flow.model, id: flowObject.flow.rbsid, kind: flowObject.flow.kind} : null
      let finfo = {flowConfiguration: flowObject.confPath, operation: operation, authenticatedUser: flowObject.context.data.env.value.system ? flowObject.context.data.env.value.system.value.userLoginId.value : null, parentExecutionId: flowObject.parentFlow ? flowObject.parentFlow.instanceId : null}
      this.flowInstance[flowObject.instanceId] = finfo
    }
  },

  getHistory: function() {
    return this.historyArr
  },

  getLog: function() {
    return this.logArr
  },

  clear: function() {
    let newHistory = []
    for (let rec of this.historyArr) {
      if (rec.executionActionId) {
        rec.logObject = {}
        rec.archived = true
        newHistory.push(rec)
      }
    }
    this.historyArr = newHistory
    this.logArr = []
    this.flowInstance = {}
    console.clear()
  },

  getFlowPath: function(flow) {
    if (flow.parentFlow) {
      var result = ''
      var sep = ''
      while (flow.parentFlow) {
        result =  flow.parentFlow.flow.id + sep + result
        sep = ' / '
        flow = flow.parentFlow
      }
      return result
    } else {
      return null
    }
  },

  listFLowLog: function(executionId, tree = false) {
    let res = {executionId: executionId}
    if (this.flowInstance[executionId]) {
      res = Object.assign(res, this.flowInstance[executionId])
    }
    res.executedAction = []
    for (let rec of this.historyArr) {
      if (rec.instanceId == executionId) {
        let act = {action: {id: rec.actionid, kind: rec.kind, name: rec.action}, start: rec.start, end: rec.end, duration: rec.duration, message: [rec.text], archived: rec.archived ? true : false}
        if (tree) {
          act.execution = {}
          if (rec.executionActionId && !rec?.logObject['Server log']) {
            act.execution = MCHistory.listFLowLog(rec.executionActionId, true)
          }
        } else {
          act.executionId = [rec.executionActionId]
        }
        if (rec.logic) {
          act.event = {kind: 'FORM LOGIC', name: rec.logic.name, id: rec.logic.id}
        } else if (rec.text) {
          let index = rec.text.indexOf(': ')
          if (index > 0) {
            act.event = {kind: rec.text.substring(0, index), name: rec.text.substring(index + 2)}
          } else {
            act.event = {kind: rec.text}
          }
        }
        if (rec.target) {
          act.action.target = rec.target
        }
        if (rec.logObject) {
          if (rec.logObject.Input) {
            act.input = {value: {data: rec.logObject.Input}}
          }
          if (rec.logObject.Output) {
            act.output = [{data: rec.logObject.Output}]
          }
          if (rec.isException) {
            act.exception = {data: rec.logObject.Output} 
            act.exceptionHandled = rec.exceptionHandled === true
          }
          if (rec.logObject['Server log']) {
            if (tree) {
              act.execution.serverSide = {flowId: rec.logObject['Server log'].flowId, flowLogId: rec.logObject['Server log'].flowLogId}
              act.execution.flowConfiguration = rec.logObject['Server log'].flowConfiguration
            } else {
              act.serverSide = {flowId: rec.logObject['Server log'].flowId, flowLogId: rec.logObject['Server log'].flowLogId}
            }
          }
          if (rec.logObject['Tested branches']) {
            act.condition = []
            for (let name in rec.logObject['Tested branches']) {
              act.condition.push({branch: name, value: rec.logObject['Tested branches'][name].result, trace: rec.logObject['Tested branches'][name].trace})
            }
          }
          if (rec.logObject['Condition']) {
            act.condition = [{branch: 'condition', value: rec.logObject['Condition'].result, trace: rec.logObject['Condition'].trace}]
          }
          if (rec.logObject.Trace) {
            let mapping = []
            for (let path in rec.logObject.Trace) {
              mapping.push({target: path, value: {data: rec.logObject.Trace[path].result}, trace: rec.logObject.Trace[path]})
            }
            act.input = {value: act.input ? act.input.value : null, mapping: mapping} 
          }
          if (rec.logObject['Environment']) {
            act.environment = rec.logObject['Environment']
          }
        }
        res.executedAction.push(act)
      }
    }
    return res
  }

}

export {MCHistory}