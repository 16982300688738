import React from "react"

import {MC} from "../client/MC.js";
import {Value} from "../client/Value.js"
import {EditableLabel} from "./EditableLabel.jsx";
import {InteractiveField} from "./InteractiveField.jsx";
import {WidgetModel} from "./WidgetModel.js";

class Field extends React.Component {

  dragCounter = 0
  dragOverTimer = undefined
  focused = false
  dragImg = null

  componentDidMount() {
    if (this.props.dragFunctionHolder) {
      this.props.dragFunctionHolder.dragStartFunction = this.dragStart
    }
    if (!MC.isModelerActive(this.props.field)) {
      let dimg = MC.getFieldParamValue(this.props.field.param, '@dragImage')
      if (dimg && typeof dimg == 'string') {
        const self = this
        const img = new Image()
        if (dimg.toLowerCase() == 'none') {
          img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='
          img.onload = () => { self.dragImg = img }
        } else if (dimg.includes('base64,')) {
          img.src = dimg
          img.onload = () => { self.dragImg = img }
        } else if (dimg.includes('.')) {
          img.src = MC.rebaseUrl(MC.findRoot(this.props.field).componentName, dimg, this.props.field.flow.baseUrl)
          img.onload = () => { self.dragImg = img }
        }  
      }
    }
  }  

  dragStart = (field, i, e) => {
    if (!MC.dragData) { // ensure that drag is not already in process from repater or table row
      if (this.dragImg) {
        e.dataTransfer.setDragImage(this.dragImg, 0, 0)
      }
      let dragData = {"widgetName": field.id == 'rows*' ? field.parent.parent.id : field.id, "widgetPath": field.flow.getFormFieldPath(field), dragIndex: i}
      if (MC.getFieldParamValue(field.param, '@iteration')) {
        dragData.widgetIndex = MC.getFieldParamValue(field.param, '@iteration')
      }
      let widgetData = Value.dataNode({})
      field.flow.mapFormOutput(i === undefined && field.parent.id !== 'rows*' ? field.parent : field.parent.parent, widgetData, null, null, 'no', dragData.widgetIndex, null)
      dragData.widgetData = i === undefined ? field.parent.id === 'rows*' ? Value.getProperty(Value.getProperty(widgetData, "0"), field.id) : Value.getProperty(widgetData, field.id) : Value.collectionItem(Value.getProperty(widgetData, 'rows'), i)
      dragData.widgetData = Value.toJson(dragData.widgetData, true)
      MC.dragData = dragData
      MC.dragField = field
      MC.handleEvent(field, 'drag', null, e, {dragData: MC.dragData})
    }
  }  

  render() {
    var modeler = this.getModelerReact();
    var field = this.props.field;
    var cls = this.props.className;
    var isModelerActive = field.isModelerActive();
    var isInPalette = field.isInPalette;
    var isPlaceholder = field.isPlaceholder();
    var isInteractive = field.isInteractive;
    var isSelectedField = field.isSelectedField();
    let style = this.props.style || {}

    if (isModelerActive && modeler.state.structuralMode && !isInPalette) {
      style.minHeight = '25px'
      style.minWidth = '25px'
      style.paddingLeft = '5px'
      style.paddingBottom = '5px'
      style.paddingRight = '5px'
      style.paddingTop = '5px'
      style.marginLeft = '5px'
      style.marginRight = '5px'
      style.marginTop = '5px'
      style.marginBottom = '5px'
      style.border = '1px solid rgba(95, 97, 115, 0.8)'
      style.color = 'rgb(95, 97, 115)'
      if (field.fields.length > 0 || modeler.state.widgetModelLoaded && WidgetModel.getSupportedChilds(WidgetModel.findWidgetByName(field.widget)).length > 0) {
        style.border = '1px solid rgba(189,152,125,1)'
        style.color = 'rgba(175,93,107,1)'
      }
      switch (this.props.wide) {
        case 'one': style.width = 'calc(8.33333333% - 10px)'; break
        case 'two': style.width = 'calc(16.66666667% - 10px)'; break
        case 'three': style.width = 'calc(25% - 10px)'; break
        case 'four': style.width = 'calc(33.33333333% - 10px)'; break
        case 'five': style.width = 'calc(41.66666667% - 10px)'; break
        case 'six': style.width = 'calc(50% - 10px)'; break
        case 'seven': style.width = 'calc(58.33333333% - 10px)'; break
        case 'eight': style.width = 'calc(66.66666667% - 10px)'; break
        case 'nine': style.width = 'calc(75% - 10px)'; break
        case 'ten': style.width = 'calc(83.33333333% - 10px)'; break
        case 'eleven': style.width = 'calc(91.66666667% - 10px)'; break
        case 'twelve': style.width = 'calc(100% - 10px)'; break
      }
    }

    var onClick = MC.handleEvent.bind(this, field, 'click', null)
    let onDblClick = MC.handleEvent.bind(this, field, 'doubleclick', null)
    var onMouseEnter = MC.handleEvent.bind(this, field, 'mouseenter', null)
    var onMouseLeave = MC.handleEvent.bind(this, field, 'mouseleave', null)
    let onKeydown = MC.handleEvent.bind(this, field, 'keydown', null)
    let onKeyup = MC.handleEvent.bind(this, field, 'keyup', null)
    var onMouseMove;
    var onMouseDown;
    var onMouseUp;
    var onContextMenu;
    var idLabel = "";
    if (['button', 'link', 'checkbox', 'radiobutton', 'steps', 'menu'].indexOf(field.widget) >= 0) { // because click must be triggered on main input not whole field
      onClick = null
      onDblClick = null
    }
    if (isModelerActive) {
      onClick = this.handleClick;
      onMouseEnter = this.handleMouseEnter;
      onMouseLeave = this.handleMouseLeave;
      onMouseMove = this.handleMouseMove;
      onMouseDown = this.handleMouseDown;
      onMouseUp = this.handleMouseUp;
      if (!isInPalette) {
        onContextMenu = this.handleContextMenu;   
      }

      if (field.getOption(["grid", "newLineAfter"]) == "yes") {
        style.borderRightWidth = '2px'
      }
      if (field.getOption(["grid", "newLineBefore"]) == "yes") {
        style.borderLeftWidth = '2px'
      }

      if (modeler.state.structuralMode && !isInPalette) {
        idLabel = <div key="idLabel" style={{textAlign: "left", fontSize: '10px', lineHeight: '85%'}}>{this.props.field.id}</div>
        idLabel = <EditableLabel field={field} type="id" widget={idLabel} labelStr={field.id} path={["id"]} nospan={true}/>
      }
    }

    if (isModelerActive && isPlaceholder && !isInteractive && !isInPalette) {
      return (<div className={cls} style={{...this.props.style, background: 'rgba(0, 0, 0, 0.2)'}} data-widget-id={this.props.dataWidgetId} data-widget-name={field.id} ref={this.props.widgetRootRef}>
                {idLabel}
                {this.props.widget}
          </div>)
    } else {
        if (isModelerActive) {
          if (modeler.state.structuralMode || isInPalette) {
            if (field.fields.length > 0 || modeler.state.widgetModelLoaded && WidgetModel.getSupportedChilds(WidgetModel.findWidgetByName(field.widget)).length > 0) {
              style.background = 'rgba(254, 247, 241, 1)'
            } else {
              style.background = 'rgba(242, 242, 242, 1)'
            }
          }
          if (isInteractive || (isSelectedField && !isInPalette)) {
            style.background = 'rgba(63, 151, 147, 0.4)'
          }
        }
        if (isInteractive) {
          style = {...style, ...field.bbox}
          style.position = 'absolute';
          style.zIndex = 2;
          style.paddingLeft = "14px";
          style.paddingRight = "14px";
          return <InteractiveField
                      field={field}
                      className={cls}
                      style={style}
                      modelerReact={modeler}
                      idLabel={idLabel}
                      widget={this.props.widget}/>;
        } else {
          let draggable = MC.getFieldParamBooleanValue(field.param, '@draggable') && !MC.isModelerActive(field)
          let droppable = MC.getFieldParamBooleanValue(field.param, '@droppable') && !MC.isModelerActive(field)
          let onDrop = null
          let onDragOver = null
          let onDragStart = null
          let onDragEnter = null
          let onDragLeave = null
          let onDragEnd = null
          if (draggable) {
            cls = MC.classes(cls, 'curgrab')
            onDragStart = this.onDragStart.bind(this)
            onDragEnd = MC.clearDragData
          }
          if (droppable) {
            onDrop = this.handleDrop.bind(this)
            onDragOver = this.onDragOver
            onDragEnter = this.onDragEnter
            onDragLeave = this.onDragLeave
          }
          if (isInPalette) {
            style.padding = '5px'
            style.marginRight = '2px'
          }
          let Tag = this.props.tdAsWrapper ? 'td' : 'div'
          return <Tag className={cls}
                      style={style}
                      onClick={onClick}
                      onDoubleClick={onDblClick}
                      onKeyDown={onKeydown}
                      onKeyUp={onKeyup}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                      onMouseDown={onMouseDown}
                      onMouseUp={onMouseUp}
                      onContextMenu={onContextMenu}
                      data-widget-id={this.props.dataWidgetId}
                      data-widget-name={field.id}
                      ref={this.props.widgetRootRef}
                      draggable={draggable}
                      onDragStart={onDragStart}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      onDragEnter={onDragEnter}
                      onDragLeave={onDragLeave}
                      onDragEnd={onDragEnd}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      tabIndex="-1">
                    {idLabel}
                    {this.props.widget}
                    </Tag>
        }
      }
    }

    handleClick = (event) => {
      event.stopPropagation()
      event.preventDefault()
    };

    handleContextMenu = (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (!event.ctrlKey && !event.metaKey) { 
        let modeler = this.getModelerReact()
        let selectedFieldIds = modeler.state.selectedFieldIds
        let id = this.props.field.rbsid
        if (selectedFieldIds.indexOf(id) == -1) {
          modeler.setSelectedField(this.props.field.rbsid)
        }
        let offset = MC.offset(document.querySelector("#modeler"))
        let position = {x: event.pageX - offset.left, y: event.pageY - offset.top}
        modeler.showInspector(position)
      }
    }

    getWidgetBBox() {
      let widget = this.props.widgetRootRef.current
      let form = document.querySelector("#modeler .mnc.form")
      let left = MC.offset(widget).left - MC.offset(form).left
      let top = MC.offset(widget).top - MC.offset(form).top
      let height = widget.offsetHeight
      let width = widget.offsetWidth
      return {left: left, top: top, height: height, width: width}
    }

    makeInteractiveField = (event) => {
      var interactiveField = this.props.field.getCopy();
      interactiveField.bbox = this.getWidgetBBox();
      interactiveField.initPosition = {x: event.pageX, y: event.pageY};
      interactiveField.isInteractive = true;
      interactiveField.originalField = this.props.field;
      var isInPalette = this.props.field.isInPalette;
      var modeler = this.getModelerReact();
      if (isInPalette) {
        interactiveField.parent = modeler.state.form;
        interactiveField.grid = [];
        interactiveField.addGrid();
        delete interactiveField.isInPalette;
      }
      var isInTable = this.props.field.isInTable();
      if ((event.shiftKey) && !isInTable && !isInPalette) {
        interactiveField.offsetChange = true;
      } else if (this.isResizePossible(event)) {
        interactiveField.resizeChange = true;
      }

      if (interactiveField.offsetChange ||  interactiveField.resizeChange) {
        let initColumns = this.props.field.columns()
        let initWidth = parseInt(MC.outerWidthIncludeMargin(this.props.widgetRootRef.current))
        interactiveField.columnWidth = initWidth / initColumns
      }

      return interactiveField;
    };

    handleMouseEnter = () => {
      var modelerReact = this.getModelerReact();
      if (!modelerReact.state.interactiveField) {
        document.body.style.cursor = "move";
      }
    };

    handleMouseLeave = () => {
      var modelerReact = this.getModelerReact();
      var parent = this.props.field.getParent();
      if (!modelerReact.state.interactiveField && ( typeof parent == "undefined" || parent.isTopLevel())) {
        document.body.style.cursor = "";
      }
    };

    handleMouseMove = (event) => {
      var modelerReact = this.getModelerReact();
      if (event.buttons != 0 && modelerReact.fieldMouseDown == this && !event.ctrlKey && !event.metaKey) {
        if (modelerReact.isDragPossible && !modelerReact.state.interactiveField) {
          var interactiveField = this.makeInteractiveField(event);
          modelerReact.setState({showPalette: false,
                                 interactiveField: interactiveField});
        }
      }
      if (!modelerReact.state.interactiveField) {
        if (this.isResizePossible(event)) {
          document.body.style.cursor = "ew-resize";
        } else {
          document.body.style.cursor = "move";
        }
        event.stopPropagation();
      }
    };

    isResizePossible = (event) => {
      let node = this.props.widgetRootRef.current
      let isRight = event.pageX > (MC.offset(node).left + node.offsetWidth - 20)
      let isInTable = this.props.field.isInTable()
      let isInPalette = this.props.field.isInPalette
      let isInlineRepeater = this.props.field.isRepeater() && 'true' == this.props.field.getOption(["param", "@inline"])
      return isRight && !isInTable && !isInPalette && !isInlineRepeater
    }

    handleMouseDown = (event) => {
      if (event.buttons == 1) { // Pouze normální klik.
        var modelerReact = this.getModelerReact();
        modelerReact.fieldMouseDown = this;

        event.stopPropagation();
        if (!this.props.field.isInPalette) {
          modelerReact.setState({showPalette: false, showInspector: false});
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault()
            modelerReact.tongueSelectedFieldId(this.props.field.rbsid);
          } else {
            modelerReact.setSelectedField(this.props.field.rbsid);
          }

          console.log(this.props.field);
        }
      }

    };

    getModelerReact() {
      return this.props.field.getModelerReact();
    }

    onDragStart = (e) => {
      this.dragStart(this.props.field, undefined, e)
    }

    onDragOver = (e) => {
      e.stopPropagation()
      e.preventDefault()
    }

    handleDrop = (e) => {
      if (MC.dragData) {
        this.dragCounter = 0
        MC.handleEvent(this.props.field, 'drop', null, e, {dragData: MC.dragData})
        MC.clearDragData(null)
      }
    }

    onDragEnter = (e) => {
      if (MC.dragData) {
        if (this.props.field.widget == 'repeater' && e.target.classList.contains('grid')) { // prevent triggering at space between rows
          return
        }
        e.preventDefault()
        this.dragCounter++
        if (this.dragCounter == 1) {
          let delay = MC.getFieldParamValue(this.props.field.param, '@dragEnterDelay')
          delay = MC.isNumeric(delay) ? parseInt(delay) : 0
          this.dragOverTimer = setTimeout(() => {
            this.dragOverTimer = undefined
            MC.handleEvent(this.props.field, 'dragenter', null, e, {dragData: MC.dragData})
          }, delay)
        }
      }      
    }

    onDragLeave = (e) => {
      if (MC.dragData) {
        if (this.props.field.widget == 'repeater' && e.target.classList.contains('grid')) { // prevent triggering at space between rows
          return
        }
        this.dragCounter--
        if (this.dragCounter == 0) {
          if (this.dragOverTimer) {
            clearTimeout(this.dragOverTimer)
            this.dragOverTimer = undefined
          } else {
            MC.handleEvent(this.props.field, 'dragleave', null, e, {dragData: MC.dragData})
          }
        }
      }   
    }

    onFocus = (e) => {
      e.stopPropagation()
      if (this.focused) return // must be checked for fields that can lost focus by changing html inside and focusing again, so related target is not inside widgetRootRef, but focus was already done
      if (e.relatedTarget && this.props.widgetRootRef.current.contains(e.relatedTarget)) return
      this.focused = true
      this.props.onFocus(e)
    }

    onBlur = (e) => {
      e.stopPropagation()
      if (e.relatedTarget && this.props.widgetRootRef.current.contains(e.relatedTarget)) return
      this.focused = false
      this.props.onBlur(e)
    }
    
}

export {Field}