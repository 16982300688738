import getBasePlacement from "../utils/getBasePlacement.js";

export function distanceAndSkiddingToXY(placement, rects, offset) {
  var basePlacement = getBasePlacement(placement);
  var invertDistance = ['left', 'top'].indexOf(basePlacement) >= 0 ? -1 : 1;

  var _ref = typeof offset === 'function' ? offset(Object.assign({}, rects, {
    placement: placement
  })) : offset,
      skidding = _ref[0],
      distance = _ref[1];

  skidding = skidding || 0;
  distance = (distance || 0) * invertDistance;
  return ['left', 'right'].indexOf(basePlacement) >= 0 ? {
    x: distance,
    y: skidding
  } : {
    x: skidding,
    y: distance
  };
}

function offset(pars) {
  let placements = ['top', 'bottom', 'right', 'left', 'auto'].reduce(function (acc, placement) {
    return acc.concat([placement, placement + '-start', placement + '-end']);
  }, [])

  var state = pars.state
  var offset = pars.options.offset
  var data = placements.reduce(function (acc, placement) {
    acc[placement] = distanceAndSkiddingToXY(placement, state.rects, offset);
    return acc;
  }, {});
  var _data$state$placement = data[state.placement],
      x = _data$state$placement.x,
      y = _data$state$placement.y;

  if (state.modifiersData.popperOffsets != null) {
    state.modifiersData.popperOffsets.x += x;
    state.modifiersData.popperOffsets.y += y;
  }

  state.modifiersData[pars.name] = data;
}


export default {
  name: 'offset',
  phase: 'main',
  requires: ['popperOffsets'],
  fn: offset,
  options: {
    offset: [0, 10]
  }
};