import React from "react"

import {Widget} from "./Widget.jsx"
import {MC} from './MC.js'

function safeExtend() {
  let options, name, src, copy, copyIsArray, clone
  let target = arguments[0]
  if (!target) {
    target = {}
  }
  let i = 1
  let length = arguments.length
  // Handle case when target is a string or something (possible in deep copy)
  if (typeof target !== "object" && !MC.isFunction(target)) {
    target = {}
  }
  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    if ((options = arguments[i]) != null) {
      // Extend the base object
      for (name in options) {
        if (name == 'parent' || name == 'flow' || name == 'reactWidget' || name == 'tableComp') {
          continue
        }
        src = target[name]
        copy = options[name]
        // Prevent never-ending loop
        if (target === copy) {
          continue
        }
        // Recurse if we're merging plain objects or arrays
        if (copy && (MC.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))) {
          if (copyIsArray) {
            copyIsArray = false
            clone = src && Array.isArray(src) ? src : []
          } else {
            clone = src && MC.isPlainObject(src) ? src : {}
          }
          // Never move original objects, clone them
          target[name] = safeExtend(clone, copy)
        } else {
          target[name] = copy
        }
      }
    }
  }
  return target
}

class TableRow extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !MC.objectEqual(nextProps, this.oldProps, true, ['parent', 'flow', 'reactWidget', 'tableComp'])
  }

  componentDidUpdate() {
    this.oldProps = safeExtend({}, this.props)
  }

  render() {
    const columnsToRender = this.props.columnsToRender 
    const subFields = this.props.subFields 
    const irow = this.props.irow
    const disabled = this.props.disabled
    const readOnly = this.props.readOnly
    const textMode = this.props.textMode 
    const isInModeler = this.props.isInModeler
    const rowParam = this.props.rowParam

    const row = []
    let colspanStack = 0
    for (let i=0; i<columnsToRender.length; i++) {
      if (colspanStack > 0) {
        colspanStack--
        continue
      }
      const subField = subFields.find(sfield => sfield.rbsid == columnsToRender[i].field.rbsid)
      if (this.props.tableComp.rowspanStack?.[subField.id] > 0) {
        this.props.tableComp.rowspanStack[subField.id]--
        if (this.props.tableComp.colspanRowspanStack?.[subField.id] > 0) {
          colspanStack = this.props.tableComp.colspanRowspanStack?.[subField.id]
        }
        continue
      }
      const column = columnsToRender[i].column
      const halign =  MC.getFieldParamValue(subField.param, '@horizontalAlignment')
      const cls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClass'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
        'ellipsis':  MC.getFieldParamBooleanValue(column, '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(column, '@collapsing'),
        'nowrap':  MC.getFieldParamBooleanValue(column, '@nowrap')})
      const visible = MC.getFieldParamBooleanValue(subField.param, '@visible')
      const inline = {}
      const percentWidth = MC.getFieldParamValue(column, '@percentWidth')

      if (percentWidth && MC.isNumeric(percentWidth) && this.props.tableComp.state.width) {
        if (window.innerWidth >= 768) {
          let width = this.props.tableComp.state.width * (parseInt(percentWidth)/100) + 'px'
          inline.width = width
          inline.maxWidth = width
        }
      }

      const minWidth = MC.getFieldParamValue(column, '@minWidth')
      if (minWidth && MC.isNumeric(minWidth) && this.props.tableComp.state.width) {
        inline.minWidth = minWidth + 'px'
      }
      const css = MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@css'))
      const colspan = rowParam?.columns?.[subField.id]?.['@colspan'] ?? null
      const rowspan = rowParam?.columns?.[subField.id]?.['@rowspan'] ?? null
      if (colspan) {
        colspanStack = parseInt(colspan) - 1
      }
      if (rowspan) {
        this.props.tableComp.rowspanStack[subField.id] = parseInt(rowspan) - 1
        if (colspan) {
          this.props.tableComp.colspanRowspanStack[subField.id] = parseInt(colspan) - 1
        }
      }
      if (visible || isInModeler) {
        if (textMode && Widget.hasTextMode(subField)) {
          if (MC.getFieldParamBooleanValue(subField.param, '@focused')) {
            MC.putFieldParamValue(subField.param, '@focused', false)
            this.props.tableComp.toFocus = {dataIndex: irow, fieldId: subField.rbsid}
          }
          if (this.props.tableComp.tdAsWrapper) {
            const tdAsWrappercls = MC.classes('mnc wide column field', MC.getFieldParamValue(subField.param, '@cssClassField'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
            'ellipsis':  MC.getFieldParamBooleanValue(column, '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(column, '@collapsing'),
            'nowrap':  MC.getFieldParamBooleanValue(column, '@nowrap')}, 'textmode', 'widget widget-' + subField.widget)  
            row.push(<td className={tdAsWrappercls} key={i} data-widget-id={subField.rbsid} data-widget-name={subField.id} tabIndex="-1" style={{...MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField')), ...inline}} colSpan={colspan} rowSpan={rowspan}>{Widget.getTextMode(subField, Widget.getValue(subField))}</td>)
          } else {
            const clsField = MC.classes(MC.getFieldParamValue(subField.param, '@cssClassField'), 'textmode', 'widget widget-' + subField.widget)
            row.push(<td className={cls} key={i} style={{...css, ...inline}} colSpan={colspan} rowSpan={rowspan}><div className={clsField} style={MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField'))} data-widget-id={subField.rbsid} data-widget-name={subField.id} tabIndex="-1">{Widget.getTextMode(subField, Widget.getValue(subField))}</div></td>)
          }
        } else {
          if (this.props.tableComp.tdAsWrapper) {
            const tdAsWrappercls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClassField'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
            'ellipsis':  MC.getFieldParamBooleanValue(column, '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(column, '@collapsing'),
            'nowrap':  MC.getFieldParamBooleanValue(column, '@nowrap')})  
            row.push(<Widget key={i} widget={subField} resolution={this.props.tableComp.props.resolution} disabled={disabled} readOnly={readOnly} textMode={textMode} tdAsWrapper={this.props.tableComp.tdAsWrapper} 
              className={tdAsWrappercls} style={{...MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField')), ...inline}} colspan={colspan} rowspan={rowspan}/>)
          } else {
            row.push(<td className={cls} key={i} style={{...css, ...inline}} colSpan={colspan} rowSpan={rowspan}><Widget key={subField.rbsid} widget={subField} resolution={this.props.tableComp.props.resolution} disabled={disabled} readOnly={readOnly} textMode={textMode}/></td>)
          }
        }
      } else {
        row.push(<td className={cls} style={css} key={i} colSpan={colspan}>&nbsp;</td>)
      }
    }
    return row

  }

}

export default TableRow