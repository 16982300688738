import getClippingRect from "../dom-utils/getClippingRect.js";
import getDocumentElement from "../dom-utils/getDocumentElement.js";
import getBoundingClientRect from "../dom-utils/getBoundingClientRect.js";
import computeOffsets from "./computeOffsets.js";
import rectToClientRect from "./rectToClientRect.js";
import { isElement } from "../dom-utils/instanceOf.js";
import mergePaddingObject from "./mergePaddingObject.js";
import expandToHashMap from "./expandToHashMap.js"; // eslint-disable-next-line import/no-unused-modules

export default function detectOverflow(state, options = {}) {

  var placement = options.placement ? options.placement : state.placement,
      boundary = options.boundary ? options.boundary : 'clippingParents',
      rootBoundary = options.rootBoundary ? options.rootBoundary : 'viewport',
      elementContext = options.elementContext ? options.elementContext : 'popper',
      altBoundary = options.altBoundary ? options.altBoundary : false,
      padding = options.padding ? options.padding : 0;
  var paddingObject = mergePaddingObject(typeof padding !== 'number' ? padding : expandToHashMap(padding, ['top', 'bottom', 'right', 'left']));
  var altContext = elementContext === 'popper' ? 'reference' : 'popper';
  var popperRect = state.rects.popper;
  var element = state.elements[altBoundary ? altContext : elementContext];
  var clippingClientRect = getClippingRect(isElement(element) ? element : element.contextElement || getDocumentElement(state.elements.popper), boundary, rootBoundary);
  var referenceClientRect = getBoundingClientRect(state.elements.reference);
  var popperOffsets = computeOffsets({
    reference: referenceClientRect,
    element: popperRect,
    strategy: 'absolute',
    placement: placement
  });
  var popperClientRect = rectToClientRect(Object.assign({}, popperRect, popperOffsets));
  var elementClientRect = elementContext === 'popper' ? popperClientRect : referenceClientRect; // positive = overflowing the clipping rect
  // 0 or negative = within the clipping rect

  var overflowOffsets = {
    top: clippingClientRect.top - elementClientRect.top + paddingObject.top,
    bottom: elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom,
    left: clippingClientRect.left - elementClientRect.left + paddingObject.left,
    right: elementClientRect.right - clippingClientRect.right + paddingObject.right
  };
  var offsetData = state.modifiersData.offset; // Offsets can be applied only to the popper element

  if (elementContext === 'popper' && offsetData) {
    var offset = offsetData[placement];
    Object.keys(overflowOffsets).forEach(function (key) {
      var multiply = ['right', 'bottom'].indexOf(key) >= 0 ? 1 : -1;
      var axis = ['top', 'bottom'].indexOf(key) >= 0 ? 'y' : 'x';
      overflowOffsets[key] += offset[axis] * multiply;
    });
  }

  return overflowOffsets;
}