import React from 'react'
import Decimal from 'decimal.js'

import {MC} from './MC.js'

class NumberFormat extends React.Component {

  static defaultProps = {decimalSeparator: '.', prefix: '', suffix: ''}

  constructor(props) {
    super(props)
    const formattedValue = this.formatValueProp()
    this.state = {value: formattedValue, numAsString: this.removeFormatting(formattedValue), mounted: false}
    this.selectionBeforeInput = {selectionStart: 0, selectionEnd: 0}
    this.onChange = this.onChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidMount() {
    this.setState({mounted: true})
  }

  componentDidUpdate(prevProps) {
    const {props, state, focusedElm} = this
    const {value: stateValue, numAsString: lastNumStr = ''} = state
    if (prevProps !== props) {
      const lastValueWithNewFormat = this.formatNumString(lastNumStr)
      const formattedValue = props.value === null || props.value === undefined  ? lastValueWithNewFormat : this.formatValueProp()
      const numAsString = this.removeFormatting(formattedValue)
      const floatValue = parseFloat(numAsString)
      const lastFloatValue = parseFloat(lastNumStr)
      //while typing set state only when float value changes || can also set state when float value is same and the format props changes || set state always when not in focus and formatted value is changed
      if (((!isNaN(floatValue) || !isNaN(lastFloatValue)) && floatValue !== lastFloatValue) || lastValueWithNewFormat !== stateValue || (focusedElm === null && formattedValue !== stateValue)) {
        this.updateValue({formattedValue, numAsString, input: focusedElm, source: 'prop', event: null})
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
    clearTimeout(this.caretPositionTimeout);
  }

  splitDecimal(numStr) {
    const hasNagation = numStr[0] === '-'
    numStr = numStr.replace('-', '')
    const parts = numStr.split('.')
    const beforeDecimal = parts[0]
    const afterDecimal = parts[1] || ''
    return {beforeDecimal, afterDecimal, hasNagation}
  }

  setCaretPosition(el, caretPos) {
    el.value = el.value
    if (el !== null) {
      if (el.createTextRange) {
        const range = el.createTextRange()
        range.move('character', caretPos)
        range.select()
        return true
      }
      // (el.selectionStart === 0 added for Firefox bug)
      if (el.selectionStart || el.selectionStart === 0) {
        el.focus()
        el.setSelectionRange(caretPos, caretPos)
        return true
      }
      el.focus()
      return false
    }
  }

  getFloatString(num = '') {
    const decimalScale  = this.props.decimalScale
    const {decimalSeparator} = this.getSeparators()
    const numRegex = this.getNumberRegex(true)
    const hasNegation = num[0] === '-'
    if (hasNegation) num = num.replace('-', '')
    if (decimalSeparator && decimalScale === 0) {
      num = num.split(decimalSeparator)[0]
    }
    num = (num.match(numRegex) || []).join('').replace(decimalSeparator, '.')
    //remove extra decimals
    const firstDecimalIndex = num.indexOf('.')
    if (firstDecimalIndex !== -1) {
      num = `${num.substring(0, firstDecimalIndex)}.${num.substring(firstDecimalIndex + 1, num.length).replace(new RegExp(decimalSeparator.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'g'), '')}`
    }
    if (hasNegation) num = '-' + num
    return num
  }

  getNumberRegex(g) {
    const decimalScale = this.props.decimalScale
    const {decimalSeparator} = this.getSeparators()
    return new RegExp('[0-9]' + (decimalSeparator && decimalScale !== 0 ? '|' + decimalSeparator.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'): ''), g ? 'g' : undefined)
  }

  getSeparators() {
    return {decimalSeparator: this.props.decimalSeparator, thousandSeparator: this.props.thousandSeparator}
  }

  getValueObject(formattedValue, numAsString) {
    const floatValue = parseFloat(numAsString)
    return {formattedValue, value: numAsString, floatValue: isNaN(floatValue) ? undefined : floatValue}
  }

  setPatchedCaretPosition(el, caretPos, currentValue) {
    // setting caret position within timeout of 0ms is required for mobile chrome, otherwise browser resets the caret position after we set it. 
    // We are also setting it without timeout so that in normal browser we don't see the flickering
    this.setCaretPosition(el, caretPos)
    this.caretPositionTimeout = setTimeout(() => {
      if (el.value === currentValue) this.setCaretPosition(el, caretPos)
    }, 0)
  }

  correctCaretPosition(value, caretPos) {
    const {prefix, suffix} = this.props
    if (value === '') return 0
    caretPos = Math.min(Math.max(caretPos, 0), value.length)
    const hasNegation = value[0] === '-';
    return Math.min(Math.max(caretPos, prefix.length + (hasNegation ? 1 : 0)), value.length - suffix.length)
  }

  getCaretPosition(inputValue, formattedValue, caretPos) {
    const numRegex = this.getNumberRegex(true)
    const inputNumber = (inputValue.match(numRegex) || []).join('')
    const formattedNumber = (formattedValue.match(numRegex) || []).join('')
    let j = 0
    for (let i = 0; i < caretPos; i++) {
      const currentInputChar = inputValue[i] || ''
      const currentFormatChar = formattedValue[j] || ''
      if (!currentInputChar.match(numRegex) && currentInputChar !== currentFormatChar) {
        continue
      }
      if (currentInputChar === '0' && currentFormatChar.match(numRegex) && currentFormatChar !== '0' && inputNumber.length !== formattedNumber.length) {
        continue
      }
      while (currentInputChar !== formattedValue[j] && j < formattedValue.length) { j++ }
      j++
    }
    return this.correctCaretPosition(formattedValue, j)
  }

  removePrefixAndSuffix(val) {
    const {prefix, suffix} = this.props
    if (val) {
      const isNegative = val[0] === '-'
      if (isNegative) val = val.substring(1, val.length)
      val = prefix && val.indexOf(prefix) === 0 ? val.substring(prefix.length, val.length) : val
      const suffixLastIndex = val.lastIndexOf(suffix)
      val = suffix && suffixLastIndex !== -1 && suffixLastIndex === val.length - suffix.length ? val.substring(0, suffixLastIndex) : val
      if (isNegative) val = '-' + val
    }
    return val
  }

  removeFormatting(val) {
    if (!val) return val
    val = this.removePrefixAndSuffix(val)
    val = this.getFloatString(val)
    return val
  }

  formatAsNumber(numStr) {
    const {decimalScale, prefix, suffix} = this.props
    const {thousandSeparator, decimalSeparator} = this.getSeparators()
    const hasDecimalSeparator = numStr.indexOf('.') !== -1;
    let { beforeDecimal, afterDecimal, hasNagation } = this.splitDecimal(numStr)
    if (decimalScale !== undefined) {
      let str = ''
      for (let i = 0; i <= decimalScale - 1; i++) {
        str += afterDecimal[i] || ''
      }
      afterDecimal = str
    }
    if (thousandSeparator) {
      let index = beforeDecimal.search(/[1-9]/)
      index = index === -1 ? beforeDecimal.length : index
      beforeDecimal = (beforeDecimal.substring(0, index) + beforeDecimal.substring(index, beforeDecimal.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + thousandSeparator))
    }
    if (prefix) beforeDecimal = prefix + beforeDecimal
    if (suffix) afterDecimal = afterDecimal + suffix
    if (hasNagation) beforeDecimal = '-' + beforeDecimal
    return beforeDecimal + ((hasDecimalSeparator && decimalSeparator) || '') + afterDecimal
  }

  formatNumString(numStr = '') {
    if (numStr === '' || numStr === '-') {
      return numStr
    } else {
      return this.formatAsNumber(numStr)
    }
  }

  formatValueProp() {
    const decimalScale = this.props.decimalScale
    if (MC.isNull(this.props.value) || this.props.value === '') {
      return ''
    }
    let value = this.props.value
    if (typeof decimalScale === 'number' && MC.isNumeric(value)) {
      value = new Decimal(value).toFixed(decimalScale)
    }
    return this.formatNumString(value)
  }

  formatNegation(value = '') {
    const negationRegex = new RegExp('(-)')
    const doubleNegationRegex = new RegExp('(-)(.)*(-)')
    const hasNegation = negationRegex.test(value)
    const removeNegation = doubleNegationRegex.test(value)
    value = value.replace(/-/g, '')
    if (hasNegation && !removeNegation) {
      value = '-' + value
    }
    return value
  }

  formatInput(value = '') {
    value = this.removePrefixAndSuffix(value)
    value = this.formatNegation(value)
    value = this.removeFormatting(value)
    return this.formatNumString(value)
  }

  isCharacterAFormat(caretPos, value) {
    const {prefix, suffix, decimalScale} = this.props
    const {decimalSeparator} = this.getSeparators()
    if ((caretPos < prefix.length || caretPos >= value.length - suffix.length || (decimalScale && value[caretPos] === decimalSeparator))) {
      return true
    }
    return false
  }

  correctInputValue(caretPos, lastValue, value) {
    const {prefix, suffix} = this.props
    const {decimalSeparator} = this.getSeparators()
    const lastNumStr = this.state.numAsString || ''
    const {selectionStart, selectionEnd} = this.selectionBeforeInput

    // finding chaged index
    let start = 0
    let end = 0
    while (lastValue[start] === value[start] && start < lastValue.length) { start++ }
    while (lastValue[lastValue.length - 1 - end] === value[value.length - 1 - end] && value.length - end > start && lastValue.length - end > start) { end++ }
    end = lastValue.length - end
    const leftBound = prefix.length
    const rightBound = lastValue.length - suffix.length
    if (value.length > lastValue.length || !value.length || start === end || (selectionStart === 0 && selectionEnd === lastValue.length) || (start === 0 && end === lastValue.length) || (selectionStart === leftBound && selectionEnd === rightBound)) {
      return value
    }
    // check whether the deleted portion has a character that is part of a format
    const deletedValues = lastValue.substr(start, end - start)
    const formatGotDeleted = !![...deletedValues].find((deletedVal, idx) => this.isCharacterAFormat(idx + start, lastValue))
    if (formatGotDeleted) {
      const deletedValuePortion = lastValue.substr(start)
      const recordIndexOfFormatCharacters = {}
      const resolvedPortion = [];
      [...deletedValuePortion].forEach((currentPortion, idx) => {
        if (this.isCharacterAFormat(idx + start, lastValue)) {
          recordIndexOfFormatCharacters[idx] = currentPortion
        } else if (idx > deletedValues.length - 1) {
          resolvedPortion.push(currentPortion)
        }
      })
      Object.keys(recordIndexOfFormatCharacters).forEach((idx) => {
        if (resolvedPortion.length > idx) {
          resolvedPortion.splice(idx, 0, recordIndexOfFormatCharacters[idx])
        } else {
          resolvedPortion.push(recordIndexOfFormatCharacters[idx])
        }
      })
      value = lastValue.substr(0, start) + resolvedPortion.join('')
    }
    const numericString = this.removeFormatting(value)
    const {beforeDecimal, afterDecimal, hasNagation} = this.splitDecimal(numericString)
    const isBeforeDecimalPoint = caretPos < value.indexOf(decimalSeparator) + 1;
    if (numericString.length < lastNumStr.length && isBeforeDecimalPoint && beforeDecimal === '' && !parseFloat(afterDecimal)) {
      return hasNagation ? '-' : ''
    }
    return value
  }

  updateValue(params) {
    const {formattedValue, input, setCaretPosition = true, source, event} = params
    let {numAsString, caretPos} = params
    const {value: lastValue, numAsString: lastNum} = this.state
    if (input) {
      if (caretPos === undefined && setCaretPosition) {
        const inputValue = params.inputValue || input.value
        const currentCaretPosition = Math.max(input.selectionStart, input.selectionEnd)
        input.value = formattedValue
        caretPos = this.getCaretPosition(inputValue, formattedValue, currentCaretPosition)
      }
      input.value = formattedValue
      if (setCaretPosition) {
        this.setPatchedCaretPosition(input, caretPos, formattedValue)
      }
    }
    if (numAsString === undefined) {
      numAsString = this.removeFormatting(formattedValue)
    }
    if (formattedValue !== lastValue) {
      this.setState({ value: formattedValue, numAsString })
      if (parseFloat(numAsString) !== parseFloat(lastNum) && MC.isFunction(this.props.onValueChange) && source == 'event') {
        this.props.onValueChange(this.getValueObject(formattedValue, numAsString), {event, source})
      }
    }
  }

  onChange(e) {
    const el = e.target
    let inputValue = el.value
    const lastValue = this.state.value || ''
    const currentCaretPosition = Math.max(el.selectionStart, el.selectionEnd)
    inputValue = this.correctInputValue(currentCaretPosition, lastValue, inputValue)
    let formattedValue = this.formatInput(inputValue) || ''
    const numAsString = this.removeFormatting(formattedValue)
    this.updateValue({formattedValue, numAsString, inputValue, input: el, event: e, source: 'event'})
  }

  onBlur(e) {
    let numAsString = this.state.numAsString
    const lastValue = this.state.value
    this.focusedElm = null
    clearTimeout(this.focusTimeout)
    clearTimeout(this.caretPositionTimeout)
    if (isNaN(parseFloat(numAsString))) {
      numAsString = ''
    }
    // fix leading zeros
    if (numAsString) {
      const isNegative = numAsString[0] === '-'
      if (isNegative) numAsString = numAsString.substring(1, numAsString.length)
      const parts = numAsString.split('.')
      const beforeDecimal = parts[0].replace(/^0+/, '') || '0'
      const afterDecimal = parts[1] || ''
      numAsString = `${isNegative ? '-' : ''}${beforeDecimal}${afterDecimal ? `.${afterDecimal}` : ''}`
    }
    const formattedValue = this.formatNumString(numAsString)
    if (formattedValue !== lastValue) {
      // the event needs to be persisted because its properties can be accessed in an asynchronous way
      this.updateValue({formattedValue, numAsString, input: e.target, setCaretPosition: false, event: e, source: 'event'})
    }
    if (MC.isFunction(this.props.onBlur)) {
      this.props.onBlur(e)
    }
  }

  onKeyDown(e) {
    const el = e.target
    const key = e.key
    const {selectionStart, selectionEnd, value = ''} = el
    let expectedCaretPosition
    const {prefix, suffix} = this.props
    const numRegex = this.getNumberRegex(false)
    const negativeRegex = new RegExp('-')
    this.selectionBeforeInput = {selectionStart, selectionEnd}
    if (key === 'ArrowLeft' || key === 'Backspace') {
      expectedCaretPosition = selectionStart - 1
    } else if (key === 'ArrowRight') {
      expectedCaretPosition = selectionStart + 1
    } else if (key === 'Delete') {
      expectedCaretPosition = selectionStart
    }
    let newCaretPosition = expectedCaretPosition
    const leftBound = prefix.length
    const rightBound = value.length - suffix.length
    if (key === 'ArrowLeft' || key === 'ArrowRight') {
      newCaretPosition = this.correctCaretPosition(value, expectedCaretPosition)
    } else if (key === 'Delete' && !numRegex.test(value[expectedCaretPosition]) && !negativeRegex.test(value[expectedCaretPosition])) {
      while (!numRegex.test(value[newCaretPosition]) && newCaretPosition < rightBound) {
        newCaretPosition++
      }
    } else if (key === 'Backspace' && !numRegex.test(value[expectedCaretPosition])) {
      // This is special case when backspace is pressed on a negative value while the cursor position is after prefix. We can't handle it on onChange because we will not have any information of keyPress
      if (selectionStart <= leftBound + 1 && value[0] === '-') {
        this.updateValue({formattedValue: value.substring(1), caretPos: newCaretPosition, input: el, event: e, source: 'event'})
      } else if (!negativeRegex.test(value[expectedCaretPosition])) {
        while (!numRegex.test(value[newCaretPosition - 1]) && newCaretPosition > leftBound) {
          newCaretPosition--
        }
        newCaretPosition = this.correctCaretPosition(value, newCaretPosition)
      }
    }
    if (newCaretPosition !== expectedCaretPosition || expectedCaretPosition < leftBound || expectedCaretPosition > rightBound) {
      e.preventDefault()
      this.setPatchedCaretPosition(el, newCaretPosition, value)
    }
  }

  onMouseUp(e) {
    const { selectionStart, selectionEnd, value = '' } = e.target
    if (selectionStart === selectionEnd) {
      const caretPosition = this.correctCaretPosition(value, selectionStart)
      if (caretPosition !== selectionStart) {
        this.setPatchedCaretPosition(e.target, caretPosition, value)
      }
    }
  }

  onFocus(e) {
    // Workaround Chrome and Safari bug https://bugs.chromium.org/p/chromium/issues/detail?id=779328 (onFocus event target selectionStart is always 0 before setTimeout)
    e.persist()
    this.focusedElm = e.target
    this.focusTimeout = setTimeout(() => {
      const el = e.target
      const {selectionStart, selectionEnd, value = ''} = el
      const caretPosition = this.correctCaretPosition(value, selectionStart)
      if (caretPosition !== selectionStart && !(selectionStart === 0 && selectionEnd === value.length)) {
        this.setPatchedCaretPosition(el, caretPosition, value)
      }
    }, 0)
  }

  render() {
    let props = this.props
    if (props.displayType === 'text') {
      return <span className={props.className} data-widget-i-name={props['data-widget-i-name']}>{this.state.value}</span>
    }
    // add input mode on element based on format prop and device once the component is mounted
    const inputMode = this.state.mounted && (props.format || (typeof navigator !== 'undefined' && !(navigator.platform && /iPhone|iPod/.test(navigator.platform)))) ? 'numeric' : undefined
    let parentProps = {disabled: props.disabled, maxLength: props.maxLength, placeholder: props.placeholder, readOnly: props.readOnly, size: props.size}
    return <input type="text" inputMode={inputMode} value={this.state.value} onChange={this.onChange} onKeyDown={this.onKeyDown} onMouseUp={this.onMouseUp} onFocus={this.onFocus} onBlur={this.onBlur}
              className={props.className} data-widget-i-name={props['data-widget-i-name']} ref={props.inputRef} {...parentProps}/>
  }
}

export default NumberFormat