import {useEffect, createRef} from 'react'

import {Dummy} from "../modeler/Dummy.jsx"
import {MC} from './MC.js'

function Iframe(props) {

  let iframeRef = createRef()
  const field = props.field

  useEffect(() => {
    if (MC.isModelerActive(field)) {
      return
    }
    if (MC.getFieldParamBooleanValue(field.param, '@fitHeightWithContent')) {
      let node = iframeRef.current
      node.onload = () => {
        let content = node.contentWindow.document.body
        let fitSelector = MC.getFieldParamValue(field.param, '@fitContentSelector')
        if (fitSelector) {
          content = content.querySelector(fitSelector)
        }
        node.setAttribute("style","height:" + (content.scrollHeight + 5) + "px;")
        let callback = function() { 
          node.setAttribute("style","height:" + (content.scrollHeight + 5) + "px;")
        }
        let observer = new MutationObserver(callback)
        observer.observe(content, {attributes: true, childList: true, subtree: true})
      }
    }
  }, [])

  if (MC.isModelerActive(field) || field.flow.reactFlow().state.fromSavedState) {
    return <Dummy key="widget" field={field}/>
  } else {
    let height = MC.getFieldParamValue(field.param, '@height') || 500
    height = MC.isNumeric(height) ? height+'px' : height
    let url = MC.getFieldParamValue(field.param, '@url')
    let sandbox = MC.getFieldParamValue(field.param, '@sandbox')
    sandbox = sandbox == true ? "" : sandbox
    return <iframe key="ifrm" ref={iframeRef} id={MC.getFieldParamValue(field.param, '@id')} src={url} style={{height: height}} className={props.className} data-widget-i-name={field.id} allowFullScreen={MC.getFieldParamBooleanValue(field.param, '@allowFullScreen')}
      allow={MC.getFieldParamValue(field.param, '@allow')} referrerPolicy={MC.getFieldParamValue(field.param, '@referrerpolicy')} sandbox={sandbox}/>
  }

}

export default Iframe