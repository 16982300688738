import React from 'react'

import {MC} from '../MC.js'

export const htmlInputAttrs = ['selected', 'defaultValue', 'defaultChecked', 'autoComplete', 'checked', 'disabled', 'id', 'name', 'readOnly', 'required', 'title', 'type', 'value']

export default class Checkbox extends React.Component {
  
  static defaultProps = {type: 'checkbox'}
  inputRef = this.props.widgetRef ? this.props.widgetRef : React.createRef()

  componentDidMount() {
    this.setIndeterminate()
  }

  componentDidUpdate() {
    this.setIndeterminate()
  }

  canToggle = () => {
    const {disabled, radio, readOnly} = this.props
    const {checked} = this.props
    return !disabled && !readOnly && !(radio && checked)
  }

  computeTabIndex = () => {
    const { disabled, tabIndex } = this.props
    if (!MC.isNull(tabIndex)) return tabIndex
    return disabled ? -1 : 0
  }

  handleClick = (e) => {
    const id = this.props.id
    const isInputClick = this.inputRef.current.contains(e.target)
    const labelEl = document.querySelectorAll('label[for="' + id + '"]')
    const isLabelClick = labelEl.length > 0 && labelEl[0].contains(e.target)
    const isRootClick = !isLabelClick && !isInputClick
    const hasId = !MC.isNull(id)
    if (this.isClickFromMouse) {
      this.isClickFromMouse = false
      if (isLabelClick && !hasId) {
        this.handleChange(e)
      }
      if (isRootClick) {
        this.handleChange(e)
      }
      e.stopPropagation()
      if (this.props.field) {
        MC.handleEvent(this.props.field, 'click', null, e)
      }
    }
  }

  handleDblClick = (e) => {
    if (this.props.field) {
      MC.handleEvent(this.props.field, 'doubleclick', null, e)
    }
  }  

  handleChange = (e) => {
    if (MC.isFunction(this.props.onClick)) {
      this.props.onClick(e)
    }
    const checked = this.props.checked
    if (!this.canToggle()) return
    this.props.onChange(e, {...this.props, checked: !checked})
  }

  handleMouseDown = (e) => {
    this.inputRef.current.focus()
    e.preventDefault()
  }

  handleMouseUp = () => {
    this.isClickFromMouse = true
  }

  setIndeterminate = () => {
    this.inputRef.current.indeterminate = !!this.props.indeterminate
  }

  partitionHTMLProps = () => {
    const inputProps = {}
    for (const prop in this.props) {
      if (htmlInputAttrs.indexOf(prop) > -1) {
        inputProps[prop] = this.props[prop]
      }
    }
    return inputProps
  }

  render() {
    const {className, disabled, label, id, name, radio, readOnly, slider, toggle, type, value, checked, indeterminate, style} = this.props
    const classes = MC.classes('mnc checkbox', className,
           {'checked': checked, 'disabled': disabled, 'indeterminate': indeterminate, 'fitted': MC.isNull(label), 'radio': radio, 'read-only': readOnly, 'slider': slider, 'toggle': toggle})
    // Do not remove empty labels, they are required by SUI CSS
    const labelElement = label || <label htmlFor={id}/>
    return (
      <div className={classes} style={style} onClick={this.handleClick} onDoubleClick={this.handleDblClick} onChange={this.handleChange} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
        <input {...this.partitionHTMLProps()} checked={checked} className='hidden' disabled={disabled} id={id} name={name} readOnly tabIndex={this.computeTabIndex()} type={type} value={value} ref={this.inputRef}/>
        {labelElement}
      </div>
    )
  }
}